import { useState, useContext, useEffect } from 'react'
import { SimpleAuthContext } from '../../../helpers/SimpleAuthProvider'
import LoadingSpinner from '../../atoms/LoadingSpinner/LoadingSpinner'
import Button from '../../atoms/Button/Button'
import "./Login.css"
import SecondaryButton from '../../atoms/Button/SecondaryButton'
import RequestAccessModal from '../../molecules/RequestAccessModal/RequestAccessModal'

export default function SimpleAuthProvider({ children }) {
  const { user, setUser } = useContext(SimpleAuthContext)
  const [isLoading, setIsLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [expectPassword, setExpectPassword] = useState(false)
  const [error, setError] = useState('')
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const sessionUser = JSON.parse(sessionStorage.getItem('user'));
    if (sessionUser) {
      setUser(sessionUser)
    }
  }, [])

  const reqPass = async () => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Expose-Headers': 'Content-Disposition',
      },
      body: JSON.stringify({ "userEmail": email })
    }
    setIsLoading(true)
    const response = await fetch(
      process.env.REACT_APP_SERVER_URL + "User/EmailNewPassword",
      requestOptions
    )
    setIsLoading(false)
    if (!response.ok) {
      const result = await response.json()
      setError(result.message);
      return;
    }
    setExpectPassword(true)
  }

  const login = async (event) => {
    if (event) {
      event.preventDefault();
    }

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Expose-Headers': 'Content-Disposition',
      },
      body: JSON.stringify({ "userEmail": email, "password": password })
    }
    setIsLoading(true)
    const response = await fetch(
      process.env.REACT_APP_SERVER_URL + "User/Authenticate",
      requestOptions
    )
    setIsLoading(false)
    if (!response.ok) {
      const result = await response.json()
      setError(result.message);
      return;
    }
    const result = await response.json()
    setUser(result);
    sessionStorage.setItem('user', JSON.stringify(result));
  }

  useEffect(() => {
    if (!user) {
      setEmail("")
      setPassword("")
      setExpectPassword("")
    }
  }, [user])


  return (
    <>
      {isLoading ? (
        <div className='LoadingSpinner'>
          <LoadingSpinner />
        </div>
      ) : (
        !user && (
          <div className='LoginContainer'>
            <div className='LoginForm'>
              <h1>Logga in</h1>
              <div className='Login'>
                <label for="email">
                  Ange e-postadress
                </label>
                <input
                  type="text"
                  name="email"
                  id="email"
                  onChange={(event) => {
                    setEmail(event.target.value)
                  }}
                  value={email}
                  disabled={expectPassword}
                />
              </div>
              {!expectPassword && (
                <div className='EmailInput'>
                  <Button
                    type="submit"
                    text="Skicka lösenord"
                    // className={buttonStyles.mainButton}
                    onClick={() => {
                      reqPass()
                    }}
                  />
                </div>
              )}
              {expectPassword && !user && (
                <>
                  <div className='Login'>
                    <label for="email">
                      Ange lösenord
                    </label>
                    <input
                      type="password"
                      name="pass"
                      id="pass"
                      onChange={(event) => {
                        setPassword(event.target.value)
                      }}
                      value={password}
                    />
                  </div>
                  <div className='ButtonContainer'>
                    <SecondaryButton
                      type="submit"
                      text="Avbryt"
                      onClick={() => {
                        setExpectPassword(false)
                        setPassword('')
                        setError(undefined)
                      }}
                    />
                    <Button
                      type="submit"
                      text="Logga in"
                      // className={buttonStyles.mainButton}
                      onClick={() => {
                        login()
                      }}
                    />



                  </div>
                </>
              )}
              {error && (
                <>
                  <div
                    style={{
                      marginTop: '30px',
                      color: 'red',
                    }}
                  >
                    {error}
                  </div>
                  <p onClick={() => setIsOpen(true)} style={{ cursor: "pointer" }}>Begär åtkomst</p>
                  {isOpen && <RequestAccessModal setIsOpen={setIsOpen} email={email} />}
                </>
              )}
            </div>
          </div>
        )
      )}
      {user && children}
    </>

  )
}