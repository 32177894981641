import { requestMaker } from './requestMaker'
import { decisionStepIds } from '../helpers/formFunctions'

export const submitNewProcess = async (
  formVals,
  processType,
  id,
  user,
  setIsLoading,
  setModalIsOpen,
  setModalMessage,
  done = true
) => {

  if (!done) {
    const res = await sendRequest(
      `Process/${processType}/${id}`,
      { ...formVals, isDraft: true },
      user,
      setIsLoading,
      setModalIsOpen,
      setModalMessage
    )
    if (res) {
      setModalMessage('Utkast sparat')
      setModalIsOpen(true)
    }
  }
  else {
    const res = await sendRequest(
      `Process/${processType}/${id}`,
      formVals,
      user,
      setIsLoading,
      setModalIsOpen,
      setModalMessage
    )
    if (res) {
      setModalMessage('Skickat')
      setModalIsOpen(true)
    }
  }
}
export const deleteDraft = async (
  formVals,
  processType,
  id,
  user,
  setIsLoading,
  setModalIsOpen,
  setModalMessage,
  done = true
) => {
  const res = await sendRequest(
    `Process/DeleteDraft/${processType}/${id}`,
    { ...formVals, isDraft: true },
    user,
    setIsLoading,
    setModalIsOpen,
    setModalMessage
  )
  if (res) {
    setModalMessage('Utkast raderat')
    setModalIsOpen(true)
  }
}

export const submitUpdateProcess = async (
  formVals,
  action,
  id,
  user,
  setIsLoading,
  setModalIsOpen,
  setModalMessage
) => {
  if (action == "needmoreinfo") {
    action = `needmoreinfo?decisionStepId=${decisionStepIds[formVals?.target]}&backToLatestDecision=${formVals?.backToLatest ? 'true' : 'false'}`
  }
  const result = await sendRequest(
    `Process/${id}/${action}`,
    formVals,
    user,
    setIsLoading,
    setModalIsOpen,
    setModalMessage
  )
  if (!result) return

  if (result?.message) {
    if (result.message === 'Status Ok') {
      if (action.startsWith("needmoreinfo")) {
        setModalMessage(
          'Tack, ett mejl har skickats för komplettering.'
        )
      }
      else {
        switch (action) {
          case 'approve':
            setModalMessage('Tack, ärendet har skickats vidare.')
            break
          case 'decline':
            setModalMessage(
              'Tack, processen har blivit avslagen och skickas tillbaka till skaparen av processen.'
            )
            break
          default:
            throw new Error('not implemented Action Type!')
        }
      }
      setModalIsOpen(true)
      return
    }
    setModalIsOpen(true)
    setModalMessage(result.message)
    return
  }
}

export const submitFinishProcess = async (
  formVals,
  id,
  user,
  setIsLoading,
  setModalIsOpen,
  setModalMessage
) => {
  const result = await sendRequest(
    `Process/${id}/approve`,
    { ...formVals, isDraft: false },
    user,
    setIsLoading,
    setModalIsOpen,
    setModalMessage
  )
  if (!result) return
  if (result?.message) {
    if (result.message === 'Status Ok') {
      setModalMessage('Tack, ärendet har avslutats.')
      setModalIsOpen(true)
      return
    }
    setModalIsOpen(true)
    setModalMessage(result.message)
    return
  }
}

export const sendEmail = async (
  id,
  formVals,
  user,
  setIsLoading,
  setModalIsOpen,
  setModalMessage
) => {
  const result = await sendRequest(
    `Process/SendEmail/${id}`,
    formVals,
    user,
    setIsLoading,
    setModalIsOpen,
    setModalMessage
  )
  if (!result) return
  if (result?.message) {
    if (result.message === 'Status Ok') {
      setModalMessage('Tack, ditt felmeddelande har skickats.')
      setModalIsOpen(true)
      return
    }
    setModalIsOpen(true)
    setModalMessage(result.message)
    return
  }
}

export const requestAccess = async (
  formVals,
  setIsLoading,
  setModalIsOpen,
  setModalMessage
) => {
  const result = await sendRequest(
    `User/RequestAccess`,
    formVals,
    { firstName: "null", lastName: "null", email: "null" },
    setIsLoading,
    setModalIsOpen,
    setModalMessage
  )
  if (!result) return
  if (result?.message) {
    if (result.message === 'Status Ok') {
      setModalMessage('Tack, din begäran har skickats. Du kommer få ett mail när du fått åtkomst.')
      setModalIsOpen(true)
      return
    }
    setModalIsOpen(true)
    setModalMessage(result.message)
    return
  }
}

export const getPage = async (
  url,
  user,
  setIsLoading
) => {
  const result = await sendRequest(
    url,
    null,
    user,
    setIsLoading,
    null,
    null
  )
  if (!result) return
}

async function sendRequest(
  endpoint,
  formVals,
  user,
  setIsLoading,
  setModalIsOpen,
  setModalMessage
) {
  const token = localStorage.getItem('token')
  setIsLoading(true)
  const result = await requestMaker(user, 'POST', endpoint, token, formVals)
  setIsLoading(false)

  if (result?.errorMessage === 'error') {
    setModalIsOpen(true)
    setModalMessage(result.result.message)
    return false
  }

  if (result?.internalError?.message) {
    setModalIsOpen(true)
    setModalMessage('Något gick fel')
    return false
  }
  return result
}
