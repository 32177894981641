import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import SuggestionExisting from '../../organisms/SuggestionExisting/SuggestionExisting'
import SuggestionNew from '../../organisms/SuggestionNew/SuggestionNew'

const SuggestionProcess = ({ setTitle }) => {
    const { id, draft } = useParams()

    useEffect(() => {
        setTitle('Förslagslåda')
    }, [])

    return (
        id ?
            <SuggestionExisting id={id} isDraft={draft} />
            :
            <SuggestionNew />
    )
}

export default SuggestionProcess