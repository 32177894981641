import { useState, useEffect, useContext } from 'react'
import BaseForm from '../BaseForm/BaseForm'
import ButtonsContainer from '../../molecules/ButtonsContainer/ButtonsContainer'
import { requestMaker } from '../../../helpers/requestMaker'
import { SimpleAuthContext } from '../../../helpers/SimpleAuthProvider'
import VehicleForm from '../VehicleForm/VehicleForm'
import Button from '../../atoms/Button/Button'
import { submitNewProcess } from '../../../helpers/processApi'
import GridTriple from '../../atoms/Grid/GridTriple'
import FileUploadInput from '../../molecules/FileUploadInput/FileUploadInput'
import TertiaryButton from '../../atoms/Button/TertiaryButton'
import { stepIndicatorsProps } from '../../../helpers/formFunctions'

const VehicleNew = () => {
  var { user } = useContext(SimpleAuthContext)

  const [formVals, setFormVals] = useState()
  const [errors, setErrors] = useState({})

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalMessage, setModalMessage] = useState('initial')
  const [dataFromFetch, setDataFromFetch] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      const data = await requestMaker(
        user,
        'GET',
        `process/default/vehicle`,
        null
      )
      setDataFromFetch(data)
      const employeeFromAD = await requestMaker(
        user,
        'POST',
        `employee/getEmployee`,
        null,
        user.email
      )
      setIsLoading(false)

      var fv = { ...data.data }
      fv.email = employeeFromAD.email
      fv.employeeNo = employeeFromAD.employeeNumber
      fv.driverName = employeeFromAD.label

      for (const key in fv) {
        if (fv[key] == 0) {
          delete fv[key]
        }
      }
      setFormVals(fv)

      if (data === 'Failed to fetch') {
        setModalIsOpen(true)
        setModalMessage('Servern kunde inte nås')
      }
    }
    fetchData()
  }, [])

  return (
    <BaseForm
      modalIsOpen={modalIsOpen}
      modalMessage={modalMessage}
      stepIndicatorsProps={stepIndicatorsProps(dataFromFetch)}
      isLoading={isLoading}
      setModalIsOpen={setModalIsOpen}
    >
      {formVals && (
        <VehicleForm
          formVals={formVals}
          setFormVals={setFormVals}
          errors={errors}
          setErrors={setErrors}
        />
      )}
      <GridTriple title={''}>
        <FileUploadInput
          labelText="Ladda upp offert"
          buttonText="Ladda upp offert"
          inputId="offerAttachment"
          processId={dataFromFetch.id}
        />
        <FileUploadInput
          labelText="Ladda upp personalbilskalkyl"
          buttonText="Ladda upp kalkyl"
          inputId="calculationAttachment"
          processId={dataFromFetch.id}
        />
        <FileUploadInput
          labelText="Ladda upp personalbilsavtal"
          buttonText="Ladda upp avtal"
          inputId="agreementAttachment"
          processId={dataFromFetch.id}
        />
      </GridTriple>
      <ButtonsContainer>
        <TertiaryButton
          text={'Spara som utkast'}
          onClick={(e) => {
            submitNewProcess(
              formVals,
              'Vehicle',
              dataFromFetch.id,
              user,
              setIsLoading,
              setModalIsOpen,
              setModalMessage,
              false
            )
          }}
        />
        <Button
          text={'Skicka för godkännande'}
          disabled={!errors.isValid}
          onClick={(e) => {
            submitNewProcess(
              formVals,
              'Vehicle',
              dataFromFetch.id,
              user,
              setIsLoading,
              setModalIsOpen,
              setModalMessage
            )
          }}
        />
      </ButtonsContainer>
    </BaseForm>
  )
}

export default VehicleNew
