import { checkPnr } from '../../../helpers/formatters'

const allProps = [
  "companyName",
  "organizationNo",
  "branchNo",
  "insuranceCompany",
  "vehicleBrand",
  "vehicleModel",
  "category",
  "costArea",
  "driverName",
  "personalNo",
  "address",
  "zipCode",
  "city",
  "mobilePhone",
  "email",
  "expectedMiles",
  "privateMiles",
  "employeeNo",
  "managerEmail",
  "supplierName",
  "supplierContact",
  "supplierEmail",
  "supplierPhone",
  "co2",
  "netPrice",
  "maxPrice",
]
const allPropsRegNo = [
  "companyName",
  "organizationNo",
  "branchNo",
  "insuranceCompany",
  "vehicleBrand",
  "vehicleModel",
  "category",
  "costArea",
  "driverName",
  "personalNo",
  "address",
  "zipCode",
  "city",
  "mobilePhone",
  "email",
  "expectedMiles",
  "privateMiles",
  "employeeNo",
  "managerEmail",
  "supplierName",
  "supplierContact",
  "supplierEmail",
  "supplierPhone",
  "co2",
  "netPrice",
  "maxPrice",
  "fromRegNo",
  "carRegNo"
]

export const newProcessValidator = (values) => {
  const e = {}
  if (values.vehicleBrand === '') {
    e.vehicleBrand = 'Bilmärke är obligatoriskt'
  }
  if (values.vehicleModel === '') {
    e.vehicleModel = 'Modell är obligatoriskt'
  }
  if (values.netPrice && values.netPrice === '') {
    e.netPrice = 'Nettopris offert är obligatoriskt'
  }
  if (values.netPrice && isNaN(values.netPrice)) {
    e.netPrice = 'Nettopris offert måste vara siffror'
  }
  if (values.netPrice > values.maxPrice) {
    e.priceTooHigh = `Nettopriset får inte överskrida maxpris: ${values.maxPrice}.`
  }
  if (values.co2 && values.co2 < 0) {
    e.co2 = 'Co2 får minst vara 0!'
  }
  if (values.co2 && values.co2 > 30) {
    e.co2 = 'Co2 får ej överskrida 30!'
  }
  if (values.co2 && isNaN(values.co2)) {
    e.co2 = 'Co2 måste vara siffror'
  }
  if (values.category === '') {
    e.category = 'Kategori är obligatoriskt!'
  }
  if (values.costArea === '') {
    e.costArea = 'Kostnadställe är obligatoriskt'
  }
  if (values.personalNo === '') {
    e.personalNo = 'Personnummer är obligatoriskt'
  }
  if (values.personalNo && !checkPnr(values.personalNo)) {
    e.personalNo = 'Kontrollera personnumret (10 siffror, inget bindestreck! xxxxxxxxxx)'
  }
  if (values.address === '') {
    e.address = 'Adress är obligatoriskt'
  }
  if (values.mobilePhone === '') {
    e.mobilePhone = 'Mobil är obligatoriskt'
  }
  if (values.zipCode === '') {
    e.zipCode = 'Postnummer är obligatoriskt'
  }
  if (values.email === '') {
    e.email = 'E-post är obligatoriskt'
  }
  if (values.city === '') {
    e.city = 'Ort är obligatoriskt'
  }
  if (values.expectedMiles === '') {
    e.expectedMiles = 'Beräknad körsträcka är obligatoriskt'
  }
  if (values.privateMiles === '') {
    e.privateMiles = 'Privata resor är obligatoriskt'
  }
  if (values.gasStations && !Object.values(values.gasStations).includes(true)) {
    e.fuelCardContainer = 'Minst en värdering måste finnas'
  }
  if (values.gasStations?.['Flyttas från registreringsnummer'] === true && (!values.carRegNo || values.carRegNo === '')) {
    e.fromRegNo = 'Registreringsnummer är obligatoriskt'
  }
  if (values.supplierName === '') {
    e.supplierName = 'Leverantörens namn är obligatoriskt'
  }
  if (values.supplierContact === '') {
    e.supplierContact = 'Kontakt är obligatoriskt'
  }
  if (values.supplierEmail === '') {
    e.supplierEmail = 'E-post är obligatoriskt'
  }
  if (values.supplierPhone === '') {
    e.supplierPhone = 'Telefon är obligatoriskt'
  }

  var enteredVals = Object.keys(values).filter(x => {
    if (values[x] != 0 && (!values[x] || isNaN(values[x]) && values[x] == ''))
      return false
    return true
  })
  if (!values.fromRegNo && !allProps.every(x => enteredVals.includes(x)) || (values.fromRegNo && values.carRegNo && !allPropsRegNo.every(x => enteredVals.includes(x)))) {
    e.submit = "Fyll i hela formuläret"
  }

  const errors = {}
  errors.car = [e.vehicleBrand, e.vehicleModel, e.netPrice, e.co2, e.priceTooHigh]
    .filter(x => !!x && x !== '')
  errors.catAndCostArea = [e.category, e.costArea]
    .filter(x => !!x && x !== '')
  errors.driver = [e.personalNo, e.address, e.mobilePhone, e.zipCode, e.email, e.city, e.expectedMiles, e.privateMiles]
    .filter(x => !!x && x !== '')
  errors.fuelCard = [e.fuelCardContainer, e.fromRegNo]
    .filter(x => !!x && x !== '')
  errors.supplier = [e.supplierName, e.supplierContact, e.supplierEmail, e.supplierPhone]
    .filter(x => !!x && x !== '')
  errors.submit = [e.submit]
    .filter(x => !!x && x !== '')

  const nrOfErrors = errors.car.length + errors.catAndCostArea.length +
    errors.driver.length + errors.fuelCard.length + errors.supplier.length + errors.submit.length

  if (nrOfErrors == 0)
    errors.isValid = true
  return errors
}

export const existingProcessValidator = (values) => {
  const e = {}
  const errors = {}
  const nrOfErrors = 0
  if (nrOfErrors == 0)
    errors.isValid = true
  return errors
}