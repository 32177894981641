import { Link, useNavigate } from 'react-router-dom'
import './Header.css'
import homeImg from '../../../assets/home.webp'
import { useEffect, useState, useContext } from 'react'
import { SimpleAuthContext } from '../../../helpers/SimpleAuthProvider'
import Button from '../../atoms/Button/Button'
import { requestMaker } from '../../../helpers/requestMaker'

const Header = ({ linkPrefix, titleNewProcess }) => {
  var { user } = useContext(SimpleAuthContext);
  const navigate = useNavigate()
  const [dataFromFetch, setDataFromFetch] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      const data = await requestMaker(
        user,
        'GET',
        'User/GetUserPermission',
        localStorage.getItem('token')
      )
      setDataFromFetch(data)
    }
    fetchData()
  }, [])

  return (
    <>
      <div className='processesList'>
        <ul>
          <img
            alt="Home button"
            className='homeButton'
            src={homeImg}
            onClick={() => {
              navigate('/')
            }}
          />
          <Button
            text={titleNewProcess}
            onClick={() => {
              navigate('/' + linkPrefix)
            }}
            overrideStyles={true}
            stylesFromParent='headerButton'
          />
          <li>
            <Link to={'/' + linkPrefix + '/ongoing'}>
              MINA PÅGÅENDE ANSÖKNINGAR
            </Link>
          </li>
          <li>
            <Link to={'/' + linkPrefix + '/completed'}>
              MINA AVSLUTADE ANSÖKNINGAR
            </Link>
          </li>
          {dataFromFetch.isDecisionMaker && (
            <>
              <li>
                <Link to={'/' + linkPrefix + '/ongoingActivities'}>
                  PÅGÅENDE AKTIVITETER
                </Link>
              </li>
              <li>
                <Link to={'/' + linkPrefix + '/completedActivities'}>
                  AVSLUTADE AKTIVITETER
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </>
  )
}

export default Header
