import styles from './Row.css'

const Row = ({ children, overrideStyle }) => {
  return !overrideStyle?.check ? (
    children?.length ? (
      <div className={styles.rowFormContainer}>
        <div className={styles.rowFormContainerItem}>{children[0]}</div>
        <div className={`${styles.rowFormContainerItem} ${styles.rightItem}`}>
          {children[1]}
        </div>
      </div>
    ) : (
      <div className={styles.rowFormContainer}>
        <div className={styles.rowFormSingleItem}>{children}</div>
      </div>
    )
  ) : (
    <div className={overrideStyle.rowChildClass}>{children}</div>
  )
}

export default Row
