import React, { useContext, useEffect, useState } from 'react'
import BaseForm from '../BaseForm/BaseForm'
import ButtonsContainer from '../../molecules/ButtonsContainer/ButtonsContainer'
import { SimpleAuthContext } from '../../../helpers/SimpleAuthProvider'
import { requestAccessToken } from '../../../helpers/requestAccessToken'
import { requestMaker } from '../../../helpers/requestMaker'
import { submitNewProcess } from '../../../helpers/processApi'
import Button from '../../atoms/Button/Button'
import SuggestionForm from '../SuggestionForm/SuggestionForm'
import { stepIndicatorsProps } from '../../../helpers/formFunctions'
import TertiaryButton from '../../atoms/Button/TertiaryButton'

const SuggestionNew = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [dataFetch, setDataFetch] = useState()
    const [modalMessage, setModalMessage] = useState('initial')
    const [isLoading, setIsLoading] = useState(false)

    const [formVals, setFormVals] = useState()
    const [errors, setErrors] = useState('init')

    var { user } = useContext(SimpleAuthContext)

    useEffect(() => {
        if (!dataFetch)
            return
        var v = { ...formVals, ...dataFetch.data }
        v.createdAt = dataFetch.createdAt
        v.initiatorEmail = dataFetch.initiator.email
        setFormVals(v)
    }, [dataFetch])

    useEffect(() => {
        const token = localStorage.getItem('token')
        if (!token) {
            requestAccessToken()
        }
        const fetchData = async () => {
            setIsLoading(true)
            const data = await requestMaker(user, 'GET', 'process/default/suggestionBox', token)
            setDataFetch(data)

            const employeeFromAD = await requestMaker(
                user,
                'POST',
                `employee/getEmployee`,
                null,
                user.email
            )
            setIsLoading(false)
            var fv = { ...data.data }
            fv.createdAt = data.createdAt
            fv.email = employeeFromAD.email
            fv.employmentNumber = employeeFromAD.employeeNumber
            fv.name = employeeFromAD.label

            for (const key in fv) {
                if (fv[key] == 0) {
                    delete fv[key]
                }
            }
            setFormVals(fv)
            setDataFetch(await data)
            setIsLoading(false)
            if (data === 'Failed to fetch') {
                setModalIsOpen(true)
                setModalMessage('Servern kunde inte nås')
            }
        }
        fetchData()
    }, [])

    return (
        <BaseForm
            modalIsOpen={modalIsOpen}
            modalMessage={modalMessage}
            stepIndicatorsProps={stepIndicatorsProps(dataFetch)}
            isLoading={isLoading}
            setModalIsOpen={setModalIsOpen}
        >
            {formVals &&
                <SuggestionForm
                    formVals={formVals}
                    setFormVals={setFormVals}
                    errors={errors}
                    setErrors={setErrors}
                />
            }
            <ButtonsContainer>
                <TertiaryButton
                    text={'Spara som utkast'}
                    onClick={(e) => {
                        submitNewProcess(
                            formVals,
                            'SuggestionBox',
                            dataFetch.id,
                            user,
                            setIsLoading,
                            setModalIsOpen,
                            setModalMessage,
                            false
                        )
                    }}
                />
                <Button
                    onClick={e => { submitNewProcess(formVals, "SuggestionBox", dataFetch.id, user, setIsLoading, setModalIsOpen, setModalMessage) }}
                    text="Skicka för godkännande"
                    disabled={!errors.isValid}
                />
            </ButtonsContainer>
        </BaseForm>
    )
}

export default SuggestionNew