import React from "react"
import "./BaseModal.css"

const BaseModal = ({ children }) => {

  return (
    <div className="modalBackground">
      <div className="popupModal">
    <button>Hej</button>
        {children}
      </div>
    </div>
  )
}

export default BaseModal