import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import OvertimeExisting from '../../organisms/OvertimeExisting/OvertimeExisting'
import OvertimeNew from '../../organisms/OvertimeNew/OvertimeNew'

const OvertimeProcess = ({ setTitle }) => {
    const { id, draft } = useParams()

    useEffect(() => {
        setTitle('Ansökan - Extra övertid')
    }, [])

    return (
        id ?
            <OvertimeExisting id={id} isDraft={draft} />
            :
            <OvertimeNew />
    )
}

export default OvertimeProcess