import Check from './Check'

const CheckBox = ({ value, label, onChange, disabled = false, isChecked = undefined, isDefaultChecked = undefined,kursivBeskrivning }) => {
  return (
    <Check
      labelText={label}
      kursivBeskrivning = {kursivBeskrivning}
      inputProps={{
        defaultChecked: isDefaultChecked,
        handleChange: onChange,
        name: 'category',
        id: value,
        type: 'checkbox',
        value: value,
        checked: isChecked,
        disabled: disabled,
      }}
    />
  )
}
export default CheckBox
