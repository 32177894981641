import { useEffect, useState, useContext } from 'react'
import './ProcessList.css'
import formatDate, { formatDecisionName } from '../../../helpers/formatters'
import { downloader, requestMaker } from '../../../helpers/requestMaker'
import { SimpleAuthContext } from '../../../helpers/SimpleAuthProvider'
import fileImg from '../../../assets/file.webp'
import { getDecisionMakerFromEnum, getDecisionMakerFromId } from '../../../helpers/formFunctions'
import LoadingSpinner from '../../atoms/LoadingSpinner/LoadingSpinner'
import { FPagination } from '@fgnp/react'
import { getPage } from '../../../helpers/processApi'

const ProcessList = ({ endpoint, process, processType = undefined, filter = undefined }) => {
  const [dataFromFetch, setDataFromFetch] = useState({ data: [] })
  const [isLoading, setIsLoading] = useState(true)
  const [sortBy, setSortBy] = useState('createdAt')
  const [sortOrderAsc, setSortOrderAsc] = useState(true)
  const [sortedData, setSortedData] = useState()
  const [tableRows, setTableRows] = useState()
  const [activePage, setActivePage] = useState(1)
  var { user } = useContext(SimpleAuthContext)

  const downloadReceipt = async (id) => {
    const result = await downloader(user, `process/${id}/GetPdf`)
    const url = window.URL.createObjectURL(new Blob([result.blob]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', result.fileName)
    link.click()
  }

  const handleClick = (link) => {
    window.location.href = link
  }

  useEffect(() => {
    if(process == "completed")
    {
    fetchCompletedProcessData()
    }
    else
    {
      fetchData()
    }
  }, [activePage])

    const fetchData = async () => {
      var localValue = sortBy
      var ep = endpoint
      if (processType)
      {
        ep += processType
      }
        ep += ('?pageNumber=' + activePage)
        ep += ('&OrderAcending=' + sortOrderAsc)
        ep += ('&FilterOn=' + localValue)
    const data = await requestMaker(user, 'GET', ep, localStorage.getItem('token'))
      if (data.internalError)
        console.error(data.internalError)
      else
        setDataFromFetch(data)
      setIsLoading(false)
    }

    const fetchCompletedProcessData = async () => {
      var localValue = sortBy
      var ep = endpoint
      if (processType)
      {
        ep += processType
      }
        ep +=  ('?isDeclined=' + filter.isDeclined) 
        ep += ('&pageNumber=' + activePage)
        ep += ('&OrderAcending=' + sortOrderAsc)
        ep += ('&FilterOn=' + localValue)
    const data = await requestMaker(user, 'GET', ep, localStorage.getItem('token'))
      if (data.internalError)
        console.error(data.internalError)
      else
        setDataFromFetch(data)
      setIsLoading(false)
    }

  // Behålls ifall sortering skall återimplementeras. Togs bort då paginering infördes.
  const handleSort = (e) => {
    sortBy === e ? setSortOrderAsc(!sortOrderAsc) : setSortBy(e)
  }

  useEffect(() => {
    if(process == "completed")
      {
        fetchCompletedProcessData()
      }
      else
      {
        fetchData()
      }
      setIsLoading(true)
  },[sortBy])
  
  useEffect(() => {
    if(process == "completed")
      {
        fetchCompletedProcessData()
      }
      else
      {
        fetchData()
      }
      setIsLoading(true)
    }, [sortOrderAsc])
    
    useEffect(() => {
    setSortOrderAsc(true)
  }, [sortBy])

  const handlePagination = (e, page) => {
    setActivePage(page)
  }

  useEffect(() => {
    if (dataFromFetch.data.length > 0) {
      setSortedData([...Object.keys(dataFromFetch?.data)]
    )
    }
  }, [dataFromFetch])

  useEffect(() => {
    if (sortedData) {
      setTableRows(sortedData
        .filter((p) => {
          if (filter) {
            var filterValue
            var filterKey
            for (const key in filter) {
              if (Object.hasOwnProperty.call(filter, key)) {
                filterValue = filter[key]
                filterKey = key
              }
            }
            return (
              dataFromFetch.data[p].hasOwnProperty(filterKey) &&
              dataFromFetch.data[p][filterKey] === filterValue
            )
          }
          return true
        })
        .map((p, index) => {
          var status
          var processName
          var decisionStepName = getDecisionMakerFromId(
            dataFromFetch.data[p]?.processDecision?.pendingDecisionStep?.id
          )
          decisionStepName = formatDecisionName(decisionStepName)

          if (!dataFromFetch.data[p]?.isFinished)
            if (decisionStepName == 'Initierad av') {
              if (dataFromFetch.data[p]?.decision.length > 0) {
                status = 'Komplettering'
              } else {
                status = 'Utkast'
              }
            } else status = decisionStepName
          else if (dataFromFetch.data[p]?.isDeclined)
            status =
              'Avslagen av' +
              ' ' +
              formatDecisionName(
                getDecisionMakerFromEnum(
                  dataFromFetch.data[p]?.decision.sort((d) => d.CreatedAt)[
                    dataFromFetch.data[p]?.decision.length - 1
                  ].decisionMaker
                )
              )
          else if (dataFromFetch.data[p]?.isFinished) status = 'Klar'

          switch (dataFromFetch.data[p]?.processType) {
            case 'LeaveApplication':
              processName = 'Ledighetsansökan'
              break
            case 'Vip':
              processName = 'VIP - Value in People'
              break
            case 'Vehicle':
              processName = 'Bilansökan'
              break
            case 'Recruiting':
              processName = 'Finders Fee – Rekryteringsbonus'
              break
            case 'Overtime':
              processName = 'Övertidsansökan'
              break
            case 'SuggestionBox':
              processName = 'Förslagslåda'
              break
            case 'Bonus':
              processName = 'Certifieringsbonus'
              break
            default:
              processName = ''
          }

          const link =
            '../' +
            dataFromFetch.data[p]?.processType +
            '/' +
            process +
            '/' +
            dataFromFetch.data[p]?.id
          return (
            <tr key={index} onClick={() => handleClick(link)}>
              <td>{processName}</td>
              <td>{dataFromFetch.data[p]?.initiatedBy}</td>
              {(processType === 'vip' || processType === 'leaveApplication') && (
                <td>{dataFromFetch.data[p]?.delegatedInitiator}</td>
              )}
              <td>{formatDate(dataFromFetch.data[p]?.createdAt)}</td>
              <td>{dataFromFetch.data[p]?.referenceNumber}</td>
              <td>{
                    dataFromFetch.data[p]?.processType === 'Vip' ?
                    JSON.parse(dataFromFetch.data[p].serializedData).NomineeManager :
                    dataFromFetch.data[p]?.managerEmail}
              </td>
              <td>
                {status}
                {status === 'Klar' && (
                  <>
                    <img
                      alt="Download file button"
                      className='downloadFileButton'
                      src={fileImg}
                      onClick={(event) => {
                        event.stopPropagation()
                        downloadReceipt(dataFromFetch.data[p]?.id)
                      }}
                    />
                  </>
                )}
              </td>
            </tr>
          )
        }))
    }
  }, [sortedData])

  // Skall sortering användas kan bortkommenterad kod tas tillbaka. 
  // Den fungerar inte till fullo men är en bit på vägen. Slutade att fungera i samband med implementering av paginering.
  // Prioriterades bort då den inte används i någon större utsträckning.
  return (
    <div className='listContainer'>
      <table className='listTable'>
        <thead className='listColumnHeader'>
          <tr>
            <th  onClick={() => handleSort('processType')} className={`${sortBy === 'processType' ? 'sorted-header' : ''
              }${sortOrderAsc ? ' asc' : ' desc'}`}
             >
              Namn
            </th>
            <th  onClick={() => handleSort('initiatedBy')} className={`${sortBy === 'initiatedBy' ? 'sorted-header' : ''
              }${sortOrderAsc ? ' asc' : ' desc'}`} >
              Skapad av
            </th>
            {(processType === 'vip' || processType === 'leaveApplication') &&
              <th  onClick={() => handleSort('delegatedInitiator')} className={`${sortBy === 'delegatedInitiator' ? 'sorted-header' : ''
                }${sortOrderAsc ? ' asc' : ' desc'}`} >
                Skapad åt
              </th>
            }
            <th  onClick={() => handleSort('createdAt')} className={`${sortBy === 'createdAt' ? 'sorted-header' : ''
              }${sortOrderAsc ? ' asc' : ' desc'}`} >
              Skapad
            </th>
         
            <th  onClick={() => handleSort('referenceNumber')} className={`${sortBy === 'referenceNumber' ? 'sorted-header' : ''
              }${sortOrderAsc ? ' asc' : ' desc'}`}  >
              Referens id
            </th>

              <th onClick={() => handleSort('manager')} className={`${sortBy === 'manager' ? 'sorted-header' : ''
              }${sortOrderAsc ? ' asc' : ' desc'}`}>
              Närmsta chef
            </th>
            
            <th  onClick={() => handleSort('status')} className={`${sortBy === 'status' ? 'sorted-header' : ''
              }${sortOrderAsc ? ' asc' : ' desc'}`} >Status</th>
          </tr>
        </thead>
        {isLoading ? <LoadingSpinner /> :
          <tbody className='listBody'>
            {tableRows}
          </tbody>}
      </table>
      <div className='paginationContainer'>
      <FPagination
        pages={dataFromFetch.totalPages}
        activePage={activePage}
        align="center"
        onChange={(e, page) => handlePagination(e, page)}
      />
      </div>
    </div>
  )
}

export default ProcessList
