import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import SupportEmailModal from '../../organisms/SupportEmail/SupportEmailModal'
import HomeMenu from '../../molecules/HomeMenu/HomeMenu'
import styles from './Home.css'

const Home = ({ setTitle }) => {
  useEffect(() => {
    setTitle('')
  }, [])
  return (
    <>
      <SupportEmailModal />
      <HomeMenu />
      <div className='homeContainer'>
        <ul>
          <h3 className={styles}>Välj process</h3>
          <Link to="/vip">
            <li>
              VIP - Value In People
            </li>
          </Link>
          <Link to="/vehicle">
            <li>
              Bilbeställning
            </li>
          </Link>
          <Link to="/leaveApplication">
            <li>
              Ledighetsansökan
            </li>
          </Link>
          <Link to="/recruiting">
            <li>
              Finders Fee - Rekryteringsbonus
            </li>
          </Link>
          <Link to="/overtime">
            <li>
              Ansökan - Extra övertid
            </li>
          </Link>
          <Link to="/suggestionBox">
            <li>
              Förslagslåda
            </li>
          </Link>
          <Link to="/bonus">
            <li>
              Ansökan - Certifieringsbonus
            </li>
          </Link>
        </ul>
      </div>
    </>
  )
}

export default Home