import React from "react"
import BaseForm from "../../organisms/BaseForm/BaseForm"
import ButtonsContainer from "../ButtonsContainer/ButtonsContainer"
import Button from "../../atoms/Button/Button"
import SecondaryButton from "../../atoms/Button/SecondaryButton"
import CheckBox from "../../atoms/Check/CheckBox"
import { updateFormVals } from "../../../helpers/update"
import { useEffect, useState } from "react"

import BaseModal from "../BaseModal/BaseModal"
import Grid from "../../atoms/Grid/Grid"
import InputText from "../Input/InputText"
import { requestAccess } from "../../../helpers/processApi"

const RequestAccessModal = ({ setIsOpen, email }) => {
  const [errors, setErrors] = useState([])
  const [formVals, setFormVals] = useState({ FirstName: "", LastName: null, Email: email, Company: "Fujitsu Sweden AB", Country: "Sweden", ManagerEmail: null, CostCentre: null, EmployeeNumber: null, EmployeeId: null, IsManager: "False" })
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalMessage, setModalMessage] = useState('initial')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const e = {}
    if (!(formVals?.FirstName && formVals?.LastName && formVals?.Email && formVals?.ManagerEmail)) {
      e.required = 'Fyll i all obligatoriskt information'
    }
    if (Object.keys(e).length !== 0) e.isValid = false
    else e.isValid = true
    setErrors(e)
  }, [formVals])

  const onSubmit = (e) => {
    requestAccess(formVals, setIsLoading, setModalIsOpen, setModalMessage)
  }

  return (
    <BaseModal>
      <BaseForm modalIsOpen={modalIsOpen} isLoading={isLoading} modalMessage={modalMessage} setModalIsOpen={setModalIsOpen}>
        <Grid title={"Begär åtkomst"} errorMsgState={errors?.required}>
          <InputText
            id={"FirstName"}
            label={"Förnamn"}
            text={formVals.FirstName}
            disabled={false}
            onChange={(e) => {
              updateFormVals({ id: "FirstName", value: e }, formVals, setFormVals)
            }}
            required={true}
          />
          <InputText
            id={"LastName"}
            label={"Efternamn"}
            text={formVals.LastName}
            disabled={false}
            onChange={(e) => {
              updateFormVals({ id: "LastName", value: e }, formVals, setFormVals)
            }}
            required={true}
          />
          <InputText
            id={"Email"}
            label={"Email"}
            text={formVals.Email}
            disabled={false}
            onChange={(e) => {
              updateFormVals({ id: "Email", value: e }, formVals, setFormVals)
            }}
            required={true}
          />
          <InputText
            id={"Company"}
            label={"Företag"}
            text={formVals.Company}
            disabled={false}
            onChange={(e) => {
              updateFormVals({ id: "Company", value: e }, formVals, setFormVals)
            }}
          />
          <InputText
            id={"Country"}
            label={"Land"}
            text={formVals.Country}
            disabled={false}
            onChange={(e) => {
              updateFormVals({ id: "Country", value: e }, formVals, setFormVals)
            }}
          />
          <InputText
            id={"ManagerEmail"}
            label={"Närmsta chefs Email"}
            text={formVals.ManagerEmail}
            disabled={false}
            onChange={(e) => {
              updateFormVals({ id: "ManagerEmail", value: e }, formVals, setFormVals)
            }}
            required={true}
          />
          <InputText
            id={"CostCentre"}
            label={"Kostnadsställe"}
            text={formVals.CostCentre}
            disabled={false}
            onChange={(e) => {
              updateFormVals({ id: "CostCentre", value: e }, formVals, setFormVals)
            }}
          />
          <InputText
            id={"EmployeeNumber"}
            label={"Anställningsnummer"}
            text={formVals.EmployeeNumber}
            disabled={false}
            onChange={(e) => {
              updateFormVals({ id: "EmployeeNumber", value: e }, formVals, setFormVals)
            }}
          />
          <InputText
            id={"EmployeeId"}
            label={"AnställningsId"}
            text={formVals.EmployeeId}
            disabled={false}
            onChange={(e) => {
              updateFormVals({ id: "EmployeeId", value: e }, formVals, setFormVals)
            }}
          />
          <CheckBox
            value={"IsManager"}
            label={"Jag är chef"}
            disabled={false}
            onChange={(e) => {
              updateFormVals({ id: "IsManager", value: e ? "True" : "False" }, formVals, setFormVals)
            }}
          />
        </Grid>
        <ButtonsContainer>
          <SecondaryButton text={"Stäng"} onClick={() => setIsOpen(false)} />
          <Button text={"Skicka begäran"} onClick={(e) => onSubmit(e)} />
        </ButtonsContainer>
      </BaseForm>
    </BaseModal>
  )
}
export default RequestAccessModal