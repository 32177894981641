import React, { useContext, useEffect, useState } from 'react'
import BaseForm from '../BaseForm/BaseForm'
import ButtonsContainer from '../../molecules/ButtonsContainer/ButtonsContainer'
import { SimpleAuthContext } from '../../../helpers/SimpleAuthProvider'
import { requestAccessToken } from '../../../helpers/requestAccessToken'
import { requestMaker } from '../../../helpers/requestMaker'
import Button from '../../atoms/Button/Button'
import SupportEmailForm from '../SupportEmailForm/SupportEmailForm'
import { sendEmail } from '../../../helpers/processApi'

const SupportEmail = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [modalMessage, setModalMessage] = useState('initial')
    const [isLoading, setIsLoading] = useState(false)

    const [formVals, setFormVals] = useState()
    const [errors, setErrors] = useState('init')
    const [id, setId] = useState()

    var { user } = useContext(SimpleAuthContext)
    useEffect(() => {
        const token = localStorage.getItem('token')
        if (!token) {
            requestAccessToken()
        }
        const fetchData = async () => {
            setIsLoading(true)
            const employeeFromAD = await requestMaker(
                user,
                'POST',
                `employee/getEmployee`,
                null,
                user.id
            )
            setIsLoading(false)

            var fv = { ...formVals }
            fv.email = employeeFromAD.email
            fv.employmentNumber = employeeFromAD.employeeNumber
            fv.name = employeeFromAD.label
            var path = window.location.pathname
            fv.process = path.split('/')[1].charAt(0).toLowerCase() + path.split('/')[1].slice(1)

            for (const key in fv) {
                if (fv[key] == 0) {
                    delete fv[key]
                }
            }
            setFormVals(fv)
            setIsLoading(false)
        }
        fetchData()
    }, [])

    return (
        <BaseForm
            modalIsOpen={modalIsOpen}
            modalMessage={modalMessage}
            isLoading={isLoading}
            setModalIsOpen={setModalIsOpen}
        >
            {formVals &&
                <SupportEmailForm
                    formVals={formVals}
                    setFormVals={setFormVals}
                    errors={errors}
                    setErrors={setErrors}
                    setId={setId}
                />
            }
            <ButtonsContainer>
                <Button
                    onClick={e => { sendEmail(id, formVals, user, setIsLoading, setModalIsOpen, setModalMessage) }}
                    text="Skicka"
                    disabled={!errors.isValid}
                />
            </ButtonsContainer>
        </BaseForm>
    )
}

export default SupportEmail