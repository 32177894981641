import { SimpleAuthContext } from '../../../helpers/SimpleAuthProvider'
import "./SignOutButton.css"
import { useContext } from 'react'
import LogoutIcon from "../../../assets/logout.svg"


const SignOutButton = () => {
    const { user, setUser } = useContext(SimpleAuthContext)


function handleLogout(){
sessionStorage.removeItem('user')
setUser(null)
    }

  return (
    <button className='SignOutButton' onClick={() => handleLogout()}>
  <img
  alt="Sign out button"
  className='SignOutButtonIcon'
  src={LogoutIcon}
/> 
Logga ut
</button>
  )
}
export default SignOutButton
