import { useState } from 'react'
import { Routes, Route } from 'react-router-dom'
import Home from './components/pages/Home/Home'
import Layout from './components/atoms/Layout/Layout'
import ProcessListOngoing from './components/organisms/ProcessList/ProcessListOngoing'
import ProcessListCompleted from './components/organisms/ProcessList/ProcessListCompleted'
import ProcessListAllFinalDecision from './components/organisms/ProcessList/ProcessListAllFinalDecision'
import ProcessListAllOngoing from './components/organisms/ProcessList/ProcessListAllOngoing'
import DraftList from './components/organisms/ProcessList/DraftList'
import {
  // AuthenticatedTemplate, Put back when using MSAL again
  useMsal,
  // ,
  // useIsAuthenticated,  Put back when using MSAL again
} from '../src/helpers/fakeMsal'
import ProcessPage from './components/pages/ProcessPage/ProcessPage'
import HomeProcessPage from './components/pages/HomeProcess/HomeProcessPage'
import ProcessListDecision from './components/organisms/ProcessList/ProcessListDecision'
import ProcessListAllDecision from './components/organisms/ProcessList/ProcessListAllDecision'
import ProcessListFinalDecision from './components/organisms/ProcessList/ProcessListFinalDecision'
import Login from './components/pages/Login/Login'
import VipProcess from './components/pages/VipProcess/VipProcess'
import RecruitProcess from './components/pages/RecruitProcess/RecruitProcess'
import OvertimeProcess from './components/pages/OvertimeProcess/OvertimeProcess'
import SuggestionProcess from './components/pages/SuggestionProcess/SuggestionProcess'
import VehicleProcess from './components/pages/VehicleProcess/VehicleProcess'

import LeaveProcess from './components/pages/LeaveProcess/LeaveProcess'
import BonusProcess from './components/pages/BonusProcess/BonusProcess'
import ProcessListAllCompleted from './components/organisms/ProcessList/ProcessListAllCompleted'

const App = () => {
  // const isAuthenticated = useIsAuthenticated()  Put back when using MSAL again
  const { instance, accounts } = useMsal()
  const name = accounts[0] && accounts[0].name
  // isAuthenticated && requestAccessToken(instance, accounts)  Put back when using MSAL again

  const [title, setTitle] = useState('')
  return (
    <>
      {
        <Layout
          formTitle={title}
          /* status={isAuthenticated}  Put back when using MSAL again*/ name={
            name
          }
        />
      }
      <Login>
        {/* <AuthenticatedTemplate>  Put back when using MSAL again */}
        <Routes>
          <Route path="/" element={<Home setTitle={setTitle} />} />
          <Route
            path="/allOngoing"
            element={
              <HomeProcessPage contentComponent={<ProcessListAllOngoing />} />
            }
          />
          <Route
            path="/allCompleted"
            element={
              <HomeProcessPage contentComponent={<ProcessListAllCompleted />} />
            }
          />
          <Route
            path="/allOngoingActivities"
            element={
              <HomeProcessPage contentComponent={<ProcessListAllDecision />} />
            }
          />
          <Route
            path="/allCompletedActivities"
            element={
              <HomeProcessPage contentComponent={<ProcessListAllFinalDecision />} />
            }
          />
          <Route
            path="/vip/*"
            element={
              <ProcessPage
                linkPrefix={'vip'}
                titleNewProcess={'NY VIP ANSÖKAN'}
                contentComponent={<VipProcess setTitle={setTitle} />}
              />
            }
          />
          <Route
            path="/vip/ongoing"
            element={
              <ProcessPage
                linkPrefix={'vip'}
                titleNewProcess={'NY VIP ANSÖKAN'}
                contentComponent={<ProcessListOngoing processType={"vip"} />}
              />
            }
          />
          <Route
            path="/vip/completed"
            element={
              <ProcessPage
                linkPrefix={'vip'}
                titleNewProcess={'NY VIP ANSÖKAN'}
                contentComponent={<ProcessListCompleted processType={'vip'} />}
              />
            }
          />
          <Route
            path="/vip/ongoingActivities"
            element={
              <ProcessPage
                linkPrefix={'vip'}
                titleNewProcess={'NY VIP ANSÖKAN'}
                contentComponent={<ProcessListDecision processType={'vip'} />}
              />
            }
          />
          <Route
            path="/vip/completedActivities"
            element={
              <ProcessPage
                linkPrefix={'vip'}
                titleNewProcess={'NY VIP ANSÖKAN'}
                contentComponent={<ProcessListFinalDecision processType={'vip'} />}
              />
            }
          />
          <Route
            path="/vip/ongoing/:id"
            element={
              <ProcessPage
                linkPrefix={'vip'}
                titleNewProcess={'NY VIP ANSÖKAN'}
                contentComponent={<VipProcess setTitle={setTitle} />}
              />
            }
          />
          <Route
            path="/vip/ongoingActivities/:id"
            element={
              <ProcessPage
                linkPrefix={'vip'}
                titleNewProcess={'NY VIP ANSÖKAN'}
                contentComponent={<VipProcess setTitle={setTitle} />}
              />
            }
          />
          <Route
            path="/vip/completed/:id"
            element={
              <ProcessPage
                linkPrefix={'vip'}
                titleNewProcess={'NY VIP ANSÖKAN'}
                contentComponent={<VipProcess setTitle={setTitle} />}
              />
            }
          />
          <Route
            path="/vehicle/ongoing"
            element={
              <ProcessPage
                linkPrefix={'vehicle'}
                titleNewProcess={'NY BILANSÖKAN'}
                contentComponent={<ProcessListOngoing processType={'vehicle'} />}
              />
            }
          />
          <Route
            path="/vehicle/completed"
            element={
              <ProcessPage
                linkPrefix={'vehicle'}
                titleNewProcess={'NY BILANSÖKAN'}
                contentComponent={<ProcessListCompleted processType={'vehicle'} />}
              />
            }
          />
          <Route
            path="/vehicle/ongoingActivities"
            element={
              <ProcessPage
                linkPrefix={'vehicle'}
                titleNewProcess={'NY BILANSÖKAN'}
                contentComponent={<ProcessListDecision processType={'vehicle'} />}
              />
            }
          />
          <Route
            path="/vehicle/completedActivities"
            element={
              <ProcessPage
                linkPrefix={'vehicle'}
                titleNewProcess={'NY BILANSÖKAN'}
                contentComponent={<ProcessListFinalDecision processType={'vehicle'} />}
              />
            }
          />
          <Route
            path="/vehicle/*"
            element={
              <ProcessPage
                linkPrefix={'vehicle'}
                titleNewProcess={'NY BILANSÖKAN'}
                contentComponent={<VehicleProcess setTitle={setTitle} />}
              />
            }
          />
          <Route
            path="/vehicle/ongoing/:id"
            element={
              <ProcessPage
                linkPrefix={'vehicle'}
                titleNewProcess={'NY BILANSÖKAN'}
                contentComponent={<VehicleProcess setTitle={setTitle} />}
              />
            }
          />
          <Route
            path="/vehicle/ongoingActivities/:id"
            element={
              <ProcessPage
                linkPrefix={'vehicle'}
                titleNewProcess={'NY BILANSÖKAN'}
                contentComponent={<VehicleProcess setTitle={setTitle} />}
              />
            }
          />
          <Route
            path="/vehicle/completed/:id"
            element={
              <ProcessPage
                linkPrefix={'vehicle'}
                titleNewProcess={'NY BILANSÖKAN'}
                contentComponent={<VehicleProcess setTitle={setTitle} />}
              />
            }
          />
          <Route
            path="/leaveApplication"
            element={
              <ProcessPage
                linkPrefix={'leaveApplication'}
                titleNewProcess={'NY LEDIGHETSANSÖKAN'}
                contentComponent={<LeaveProcess setTitle={setTitle} />}
              />
            }
          />
          <Route
            path="/leaveApplication/ongoing/:id"
            element={
              <ProcessPage
                linkPrefix={'leaveApplication'}
                titleNewProcess={'NY LEDIGHETSANSÖKAN'}
                contentComponent={
                  <LeaveProcess setTitle={setTitle} />
                }
              />
            }
          />
          <Route
            path="/leaveApplication/ongoingActivities/:id"
            element={
              <ProcessPage
                linkPrefix={'leaveApplication'}
                titleNewProcess={'NY LEDIGHETSANSÖKAN'}
                contentComponent={
                  <LeaveProcess setTitle={setTitle} />
                }
              />
            }
          />
          <Route
            path="/leaveApplication/completed/:id"
            element={
              <ProcessPage
                linkPrefix={'leaveApplication'}
                titleNewProcess={'NY LEDIGHETSANSÖKAN'}
                contentComponent={
                  <LeaveProcess setTitle={setTitle} />
                }
              />
            }
          />
          <Route
            path="/leaveApplication/ongoing"
            element={
              <ProcessPage
                linkPrefix={'leaveApplication'}
                titleNewProcess={'NY LEDIGHETSANSÖKAN'}
                contentComponent={<ProcessListOngoing processType={'leaveApplication'} />}
              />
            }
          />
          <Route
            path="/leaveApplication/completed"
            element={
              <ProcessPage
                linkPrefix={'leaveApplication'}
                titleNewProcess={'NY LEDIGHETSANSÖKAN'}
                contentComponent={<ProcessListCompleted processType={'leaveApplication'} />}
              />
            }
          />
          <Route
            path="/leaveApplication/ongoingActivities"
            element={
              <ProcessPage
                linkPrefix={'leaveApplication'}
                titleNewProcess={'NY LEDIGHETSANSÖKAN'}
                contentComponent={<ProcessListDecision processType={'leaveApplication'} />}
              />
            }
          />
          <Route
            path="/leaveApplication/completedActivities"
            element={
              <ProcessPage
                linkPrefix={'leaveApplication'}
                titleNewProcess={'NY LEDIGHETSANSÖKAN'}
                contentComponent={<ProcessListFinalDecision processType={'leaveApplication'} />}
              />
            }
          />
          <Route
            path="/recruiting"
            element={
              <ProcessPage
                linkPrefix={'recruiting'}
                titleNewProcess={'NYTT REKRYTERINGSTIPS'}
                contentComponent={<RecruitProcess setTitle={setTitle} />}
              />
            }
          />
          <Route
            path="/recruiting/ongoing/:id"
            element={
              <ProcessPage
                linkPrefix={'recruiting'}
                titleNewProcess={'NYTT REKRYTERINGSTIPS'}
                contentComponent={<RecruitProcess setTitle={setTitle} />}
              />
            }
          />
          <Route
            path="/recruiting/completed/:id"
            element={
              <ProcessPage
                linkPrefix={'recruiting'}
                titleNewProcess={'NYTT REKRYTERINGSTIPS'}
                contentComponent={<RecruitProcess setTitle={setTitle} />}
              />
            }
          />
          <Route
            path="/recruiting/ongoingActivities/:id"
            element={
              <ProcessPage
                linkPrefix={'recruiting'}
                titleNewProcess={'NYTT REKRYTERINGSTIPS'}
                contentComponent={<RecruitProcess setTitle={setTitle} />}
              />
            }
          />
          <Route
            path="/recruiting/ongoing"
            element={
              <ProcessPage
                linkPrefix={'recruiting'}
                titleNewProcess={'NYTT REKRYTERINGSTIPS'}
                contentComponent={<ProcessListOngoing processType={'recruiting'} />}
              />
            }
          />
          <Route
            path="/recruiting/completed"
            element={
              <ProcessPage
                linkPrefix={'recruiting'}
                titleNewProcess={'NYTT REKRYTERINGSTIPS'}
                contentComponent={<ProcessListCompleted processType={'recruiting'} />}
              />
            }
          />
          <Route
            path="/recruiting/ongoingActivities"
            element={
              <ProcessPage
                linkPrefix={'recruiting'}
                titleNewProcess={'NYTT REKRYTERINGSTIPS'}
                contentComponent={<ProcessListDecision processType={'recruiting'} />}
              />
            }
          />
          <Route
            path="/recruiting/completedActivities"
            element={
              <ProcessPage
                linkPrefix={'recruiting'}
                titleNewProcess={'NYTT REKRYTERINGSTIPS'}
                contentComponent={<ProcessListFinalDecision processType={'recruiting'} />}
              />
            }
          />
          <Route
            path="/overtime"
            element={
              <ProcessPage
                linkPrefix={'overtime'}
                titleNewProcess={'NY ÖVERTIDSANSÖKAN'}
                contentComponent={<OvertimeProcess setTitle={setTitle} />}
              />
            }
          />
          <Route
            path="/overtime/ongoing/:id"
            element={
              <ProcessPage
                linkPrefix={'overtime'}
                titleNewProcess={'NY ÖVERTIDSANSÖKAN'}
                contentComponent={<OvertimeProcess setTitle={setTitle} />}
              />
            }
          />
          <Route
            path="/overtime/ongoingActivities/:id"
            element={
              <ProcessPage
                linkPrefix={'overtime'}
                titleNewProcess={'NY ÖVERTIDSANSÖKAN'}
                contentComponent={<OvertimeProcess setTitle={setTitle} />}
              />
            }
          />
          <Route
            path="/overtime/completed/:id"
            element={
              <ProcessPage
                linkPrefix={'overtime'}
                titleNewProcess={'NY ÖVERTIDSANSÖKAN'}
                contentComponent={<OvertimeProcess setTitle={setTitle} />}
              />
            }
          />
          <Route
            path="/overtime/ongoing"
            element={
              <ProcessPage
                linkPrefix={'overtime'}
                titleNewProcess={'NY ÖVERTIDSANÖKAN'}
                contentComponent={<ProcessListOngoing processType={'overtime'} />}
              />
            }
          />
          <Route
            path="/overtime/completed"
            element={
              <ProcessPage
                linkPrefix={'overtime'}
                titleNewProcess={'NY ÖVERTIDSANÖKAN'}
                contentComponent={<ProcessListCompleted processType={'overtime'} />}
              />
            }
          />
          <Route
            path="/overtime/ongoingActivities"
            element={
              <ProcessPage
                linkPrefix={'overtime'}
                titleNewProcess={'NY ÖVERTIDSANSÖKAN'}
                contentComponent={<ProcessListDecision processType={'overtime'} />}
              />
            }
          />
          <Route
            path="/overtime/completedActivities"
            element={
              <ProcessPage
                linkPrefix={'overtime'}
                titleNewProcess={'NY ÖVERTIDSANSÖKAN'}
                contentComponent={<ProcessListFinalDecision processType={'overtime'} />}
              />
            }
          />
          <Route
            path="/suggestionBox"
            element={
              <ProcessPage
                linkPrefix={'suggestionBox'}
                titleNewProcess={'NYTT FÖRSLAG'}
                contentComponent={<SuggestionProcess setTitle={setTitle} />}
              />
            }
          />
          <Route
            path="/suggestionBox/ongoing"
            element={
              <ProcessPage
                linkPrefix={'suggestionBox'}
                titleNewProcess={'NYTT FÖRSLAG'}
                contentComponent={<ProcessListOngoing processType={'suggestionBox'} />}
              />
            }
          />
          <Route
            path="/suggestionBox/completed"
            element={
              <ProcessPage
                linkPrefix={'suggestionBox'}
                titleNewProcess={'NYTT FÖRSLAG'}
                contentComponent={<ProcessListCompleted processType={'suggestionBox'} />}
              />
            }
          />
          <Route
            path="/suggestionBox/ongoingActivities"
            element={
              <ProcessPage
                linkPrefix={'suggestionBox'}
                titleNewProcess={'NYTT FÖRSLAG'}
                contentComponent={<ProcessListDecision processType={'suggestionBox'} />}
              />
            }
          />
          <Route
            path="/suggestionBox/completedActivities"
            element={
              <ProcessPage
                linkPrefix={'suggestionBox'}
                titleNewProcess={'NYTT FÖRSLAG'}
                contentComponent={<ProcessListFinalDecision processType={'suggestionBox'} />}
              />
            }
          />
          <Route
            path="/suggestionBox/ongoing/:id"
            element={
              <ProcessPage
                linkPrefix={'suggestionBox'}
                titleNewProcess={'NYTT FÖRSLAG'}
                contentComponent={<SuggestionProcess setTitle={setTitle} />}
              />
            }
          />
          <Route
            path="/suggestionBox/ongoingActivities/:id"
            element={
              <ProcessPage
                linkPrefix={'suggestionBox'}
                titleNewProcess={'NYTT FÖRSLAG'}
                contentComponent={<SuggestionProcess setTitle={setTitle} />}
              />
            }
          />
          <Route
            path="/suggestionBox/completed/:id"
            element={
              <ProcessPage
                linkPrefix={'suggestionBox'}
                titleNewProcess={'NYTT FÖRSLAG'}
                contentComponent={<SuggestionProcess setTitle={setTitle} />}
              />
            }
          />
          <Route
            path="/bonus"
            element={
              <ProcessPage
                linkPrefix={'bonus'}
                titleNewProcess={'NY CERTIFIERINGSBONUS'}
                contentComponent={<BonusProcess setTitle={setTitle} />}
              />
            }
          />
          <Route
            path="/bonus/ongoing"
            element={
              <ProcessPage
                linkPrefix={'bonus'}
                titleNewProcess={'NY CERTIFIERINGSBONUS'}
                contentComponent={<ProcessListOngoing processType={'bonus'} />}
              />
            }
          />
          <Route
            path="/bonus/completed"
            element={
              <ProcessPage
                linkPrefix={'bonus'}
                titleNewProcess={'NY CERTIFIERINGSBONUS'}
                contentComponent={<ProcessListCompleted processType={'bonus'} />}
              />
            }
          />
          <Route
            path="/bonus/ongoingActivities"
            element={
              <ProcessPage
                linkPrefix={'bonus'}
                titleNewProcess={'NY CERTIFIERINGSBONUS'}
                contentComponent={<ProcessListDecision processType={'bonus'} />}
              />
            }
          />
          <Route
            path="/bonus/completedActivities"
            element={
              <ProcessPage
                linkPrefix={'bonus'}
                titleNewProcess={'NY CERTIFIERINGSBONUS'}
                contentComponent={<ProcessListFinalDecision processType={'bonus'} />}
              />
            }
          />
          <Route
            path="/bonus/ongoing/:id"
            element={
              <ProcessPage
                linkPrefix={'bonus'}
                titleNewProcess={'NY CERTIFIERINGSBONUS'}
                contentComponent={<BonusProcess setTitle={setTitle} />}
              />
            }
          />
          <Route
            path="/bonus/ongoingActivities/:id"
            element={
              <ProcessPage
                linkPrefix={'bonus'}
                titleNewProcess={'NY CERTIFIERINGSBONUS'}
                contentComponent={<BonusProcess setTitle={setTitle} />}
              />
            }
          />
          <Route
            path="/bonus/completed/:id"
            element={
              <ProcessPage
                linkPrefix={'bonus'}
                titleNewProcess={'NY CERTIFIERINGSBONUS'}
                contentComponent={<BonusProcess setTitle={setTitle} />}
              />
            }
          />

          <Route
            path='/drafts'
            element={
              <HomeProcessPage
                linkPrefix={'drafts'}
                contentComponent={<DraftList />}
              />
            }
          />
        </Routes>
        {/* </AuthenticatedTemplate>   Put back when using MSAL again*/}
      </Login>
    </>
  )
}

export default App
