import React from 'react'
import Grid from './Grid'

const GridTriple = ({ title = undefined, errorMsgState = undefined, children, disabled = false }) => {
    
    return (
        <Grid
            title={title}
            gridTemplateColumnsCss={"33% 33% 33%"}
            disabled={disabled}
            errorMsgState={errorMsgState}
        >
            {children}
        </Grid>
    )
}

export default GridTriple