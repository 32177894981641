import { formatDecisionName } from '../../../helpers/formatters'
import './StepIndicator.css'

const StepIndicator = ({ stepIndicatorsProps }) => {
  const steps = []
  for (let index = 1; index <= stepIndicatorsProps.totalSteps; index++) {
    {
      steps.push(
        <div key={`stepNumber${index}`} className='outerContainer'>
          <div className='innerContainer'>
            <span
              className={"stepCircle" + (index <= stepIndicatorsProps.currentStep ? " active" : "")}
              id={"step" + index}
            >
              {index}
            </span>
            <p className='stepName'>
              {formatDecisionName(stepIndicatorsProps.stepName[index - 1])}
            </p>
          </div>
          <span className='horizontalLine'></span>
        </div>
      )
    }
  }

  return (
    <div className='stepIndicator'>
      {steps.map((item) => {
        return item
      })}
    </div>
  )
}

export default StepIndicator
