import React from 'react'
import './Input.css'
import validateStyles from '../../../styles/Common/validateStyles.module.css'

const InputDate = ({ id, label, text, onChange = undefined, onBlur = undefined, validationMsg = undefined, disabled = true }) => {
    var cssClass = 'inputText'
    if (disabled)
        cssClass += " " + 'disableInputText'
    if (validationMsg)
        cssClass += " " + validateStyles.notValid

    return (
        <div>
            <label className='label' htmlFor={id}>{label}</label>
            <input
                id={id}
                className={cssClass}
                onChange={(e) => {
                    onChange(e.target.value)
                }}
                onBlur={onBlur}
                type={"date"}
                disabled={disabled}
                value={text}
            />
            {validationMsg && (
                <p className={validateStyles.errorParagraph}>{validationMsg}</p>
            )}
        </div>
    )
}

export default InputDate