import React from 'react'
import HomeMenu from '../../molecules/HomeMenu/HomeMenu'
import SupportEmailModal from '../../organisms/SupportEmail/SupportEmailModal'

const HomeProcessPage = ({ contentComponent }) => {
  return (
    <>
      <HomeMenu />
      <SupportEmailModal />
      {contentComponent}
    </>
  )
}

export default HomeProcessPage
