import React, { useState } from 'react'
import Select from 'react-select'
import styles from './Select.css'

const DropdownSearch = ({ label, searchString, defaultValue, setSearchString, options, placeholder, onSelect, disabled }) => {
    const [selectedValue, setSelectedValue] = useState(defaultValue)

    const handleSelectChange = (selectedOption) => {
        setSelectedValue(selectedOption)
        onSelect(selectedOption)
    }

    return (
        <div>
            <div>{label}
                <Select
                    options={options}
                    value={selectedValue}
                    inputValue={searchString}
                    placeholder={placeholder}
                    isClearable={true}
                    isDisabled={disabled}
                    onChange={handleSelectChange}
                    onInputChange={(e) => {
                        setSearchString(e)
                    }}
                    id={styles.selectFromReactSelect}
                    className='inputTextTemp'
                />
            </div>
        </div>
    )
}

export default DropdownSearch
