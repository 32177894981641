import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import BonusExisting from '../../organisms/BonusExisting/BonusExisting'
import BonusNew from '../../organisms/BonusNew/BonusNew'

const BonusProcess = ({ setTitle }) => {
    const { id } = useParams()

    useEffect(() => {
        setTitle('Ansökan - Certifieringsbonus')
    }, [])

    return (
        id ?
            <BonusExisting id={id} />
            :
            <BonusNew />
    )
}

export default BonusProcess