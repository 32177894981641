import ProcessList from './ProcessList'
import './MultiProcessList.css'

const ProcessListAllDecision = () => {
  return (
    <div className='multi_processList'>
      <h1 className='processList_row--header'>VÄNTAR PÅ DIG</h1>
      <ProcessList endpoint={'Processes/WaitingUsersDecision/'} process={"ongoingActivities"} />
      <h1 className='processList_row--header'>KOMMANDE</h1>
      <ProcessList endpoint={'Processes/WillNeedUsersDecision/'} process={"ongoingActivities"} />
      <h1 className='processList_row--header'>PROCESSER MED STATUS KOMPLETTERING</h1>
      <ProcessList endpoint={'Processes/DoneUsersDecision/'} process={"ongoingActivities"} />
    </div>
  )
}

export default ProcessListAllDecision