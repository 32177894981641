import './InputTextArea.css'

const InputTextArea = ({ id, text, onChange, title, disabled = false,kursivBeskrivning }) => {
  var cssClass = 'TextArea'
  return (
    <div style={{
      display: 'grid',
      gridTemplateColumns: '100%'
    }}
    >
      <label htmlFor={id}>{title}</label>
      {kursivBeskrivning != null ? <i>{kursivBeskrivning}</i> : null}
      <textarea
        id={id}
        className={cssClass}
        maxLength="65536"
        disabled={disabled}
        defaultValue={text}
        onChange={(e) => {
          onChange(e.target.value)
        }}
      />
    </div>
  )
}

export default InputTextArea
