import React from 'react'
import './Button.css'

const TertiaryButton = ({ text, onClick, overrideStyles = false, stylesFromParent, id, disabled }) => {
    var cssClass = disabled ? "tertiaryButtonDisabled" : (overrideStyles ? stylesFromParent : "tertiaryButton")
    return (
        <button
            type='button'
            className={cssClass}
            onClick={onClick}
            id={id}
            disabled={disabled}
        >
            {text}
        </button>
    )
}

export default TertiaryButton