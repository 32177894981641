import React, { useContext, useEffect, useState } from 'react'
import Row from '../../atoms/Row/Row'
import { requestMaker } from '../../../helpers/requestMaker'
import { SimpleAuthContext } from '../../../helpers/SimpleAuthProvider'
import OvertimeForm from '../OvertimeForm/OvertimeForm'
import BaseForm from '../BaseForm/BaseForm'
import { useNavigate } from 'react-router-dom'
import { useMsal } from '@azure/msal-react'
import { requestAccessToken } from '../../../helpers/requestAccessToken'
import Colors from '../../../assets/Color'
import DecisionMaker from '../../../assets/DecisionMaker'
import GridSingles from '../../atoms/Grid/GridSingles'
import InputText from '../../molecules/Input/InputText'
import InputTextArea from '../../molecules/InputTextArea/InputTextArea'
import { updateFormVals } from '../../../helpers/update'
import formatDate from '../../../helpers/formatters'
import Grid from '../../atoms/Grid/Grid'
import {
  stepIndicatorsProps,
  isCompleted,
  isFurtherInfoStep,
  isStep,
} from '../../../helpers/formFunctions'
import FormButtons from '../FormButtons/FormButtons'

const OvertimeExisting = ({ id }) => {
  const [update, setUpdate] = useState('init')
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [dataFetch, setDataFetch] = useState()
  const [modalMessage, setModalMessage] = useState('initial')
  const [isLoading, setIsLoading] = useState(false)
  const [formVals, setFormVals] = useState()
  const [errors, setErrors] = useState('')

  const navigate = useNavigate()
  const { instance, accounts } = useMsal()
  var { user } = useContext(SimpleAuthContext)

  useEffect(() => {
    if (!dataFetch) return

    var vals = { ...dataFetch.data }
    vals.createdAt = dataFetch.createdAt
    vals.initiatorEmail = dataFetch.initiator.email
    setFormVals(vals)
  }, [dataFetch])

  useEffect(() => {
    let token = localStorage.getItem('token')
    if (!token) {
      requestAccessToken(instance, accounts)
      token = localStorage.getItem('token')
    }
    async function fetcher() {
      setIsLoading(true)
      const dataFromRequest = await requestMaker(
        user,
        'GET',
        `process/${id}`,
        token,
      )

      setIsLoading(false)
      if (dataFromRequest?.errorMessage === 'error') {
        setModalIsOpen(true)
        setModalMessage(dataFromRequest?.result?.message)
      }
      if (!dataFromRequest) navigate('/')
      setDataFetch(dataFromRequest)
    }
    fetcher()
  }, [navigate, id, /*accounts, Put back when using MSAL */ instance])

  useEffect(() => {
    if (update == 'init')
      return

    updateFormVals(update, formVals, setFormVals)
  }, [update])

  return (
    <BaseForm
      modalIsOpen={modalIsOpen}
      modalMessage={modalMessage}
      stepIndicatorsProps={stepIndicatorsProps(dataFetch)}
      isLoading={isLoading}
      setModalIsOpen={setModalIsOpen}
    >
      {dataFetch && formVals && (
        <>
          <OvertimeForm
            formVals={formVals}
            setFormVals={setFormVals}
            errors={errors}
            setErrors={setErrors}
            disabled={!((isFurtherInfoStep(dataFetch) || dataFetch.isDraft) && dataFetch.isAuthorized)}
          />

          <GridSingles title={'Kommentarer'}>
            <InputTextArea
              id={'hrComment'}
              title={'HR kommentar'}
              onChange={(e) => setUpdate({ id: "hrComment", value: e })}
              text={formVals.hrComment}
              disabled={!(isStep(dataFetch, DecisionMaker.HR) && dataFetch.isAuthorized)}
            />
            <InputTextArea
              id={'unionComment'}
              title={'Facklig representant kommentar'}
              onChange={(e) => setUpdate({ id: "unionComment", value: e })}
              text={formVals.unionComment}
              disabled={!(isStep(dataFetch, DecisionMaker.UNION) && dataFetch.isAuthorized)}
            />
            <InputTextArea
              id={'payrollComment'}
              title={'Payroll kommentar'}
              onChange={(e) => setUpdate({ id: "payrollComment", value: e })}
              text={formVals.payrollComment}
              disabled={!(isStep(dataFetch, DecisionMaker.PAYROLL) && dataFetch.isAuthorized)}
            />
          </GridSingles>
          <Grid title={'Underskrift'}>
            <InputText
              id={'HrSignature'}
              label={'Underskrift av HR'}
              text={formVals.hrSignature}
            />
            <InputText
              id={'HrSignatureDate'}
              label={'Underskrift datum'}
              text={formatDate(formVals.hrSignatureDate)}
            />
            <InputText
              id={'unionSignature'}
              label={'Underskrift av facklig organisation'}
              text={formVals.unionSignature}
            />
            <InputText
              id={'unionSignatureDate'}
              label={'Underskrift datum'}
              text={formatDate(formVals.unionSignatureDate)}
            />
            <InputText
              id={'payrollSignature'}
              label={'Underskrift av Payroll'}
              text={formVals.payrollSignature}
            />
            <InputText
              id={'payrollSignatureDate'}
              label={'Underskrift datum'}
              text={formatDate(formVals.payrollSignatureDate)}
            />
          </Grid>
          {!isCompleted(dataFetch) ?
            dataFetch.isAuthorized &&
            <FormButtons
              dataFetch={dataFetch}
              user={user}
              formVals={formVals}
              setFormVals={setFormVals}
              setModalIsOpen={setModalIsOpen}
              setModalMessage={setModalMessage}
              setIsLoading={setIsLoading}
              errors={errors}
              id={id}
            />
            :
            <Row>
              <h4 style={{ color: Colors.RED }}>
                {dataFetch?.processDecision?.isDeclined
                  ? <>
                    Processen är nekad
                    <br />
                    {`Kommentar för avslag: ${dataFetch?.decisions[dataFetch?.decisions.length - 1].comment}`}
                  </>
                  : dataFetch?.processDecision?.isFinished
                  && 'Processen är klar'}
              </h4>
            </Row>
          }
        </>
      )
      }
    </BaseForm>
  )
}

export default OvertimeExisting
