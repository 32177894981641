import * as React from 'react'

const SimpleAuthContext = React.createContext()

function SimpleAuthProvider({children}) {
  const [user, setUser] = React.useState(null)
  const value = {user, setUser}
  return <SimpleAuthContext.Provider value={value}>{children}</SimpleAuthContext.Provider>
}

export {SimpleAuthProvider, SimpleAuthContext}