import React, { Children, cloneElement } from 'react'
import './Grid.css'
import validateStyles from '../../../styles/Common/validateStyles.module.css'

const Grid = ({ title = undefined, gridTemplateColumnsCss, errorMsgState = undefined, children, disabled = false }) => {
    var nChildren = []
    var eMsg

    if (errorMsgState) {
        eMsg = errorMsgState
        if (!Array.isArray(errorMsgState))
            eMsg = [eMsg]
    }

    if (!disabled)
        nChildren = children
    else
        nChildren = Children.map(children, child => {
            return cloneElement(child, { disabled: true });
        })


    return (
        <>
            {title && <h5 className='sectionHeader' style={{ marginTop: '15px' }}>{title}</h5>}
            <div style={{
                display: 'grid',
                gridTemplateColumns: gridTemplateColumnsCss ?? '50% 50%',
                marginTop: '5px',
                marginBottom: '5px',
            }
            }>
                {nChildren}
            </div>

            {eMsg &&
                eMsg.map(x => < p key={x} className={validateStyles.errorParagraph}>{x}</p >)
            }
        </>
    )
}

export default Grid