import './Layout.css'
import { useContext } from 'react'
import fujitsuLogo from '../../../assets/fujitsu.webp'
import SignOutButton from '../Button/SignOutButton'
import { SimpleAuthContext } from '../../../helpers/SimpleAuthProvider'

const Layout = ({ formTitle, name }) => {
  if (name?.split(',')?.length === 2) {
    name = name.split(',')[1] + ' ' + name.split(',')[0]
  }


  const { user, setUser } = useContext(SimpleAuthContext)
  return (
    <div className='headerLayout'>
      <div className='logoAndName'>
        <img
          className='fujistuImg'
          src={fujitsuLogo}
          alt="Fujitsu logo"
        />
        <div className='appName'>Digital Forms</div>
      </div>
      {formTitle && <div className='title'>{formTitle}</div>}
      <div className='logInOutContainer'>
        {name && <h5 className='name'> {name} </h5>}
        {user && <SignOutButton />}
      </div>
    </div>
  )
}

export default Layout
