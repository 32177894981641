import { useRef, useState, useContext } from 'react'
import { fileUploader } from '../../../helpers/requestMaker'
import './FileUploadInput.css'
import { SimpleAuthContext } from '../../../helpers/SimpleAuthProvider'
import add from "../../../assets/add.svg"

const FileUploadInput = ({ labelText, buttonText, inputId, processId }) => {
  var { user } = useContext(SimpleAuthContext)

  const [selectedFile, setSelectedFile] = useState()
  const [isSelected, setIsSelected] = useState(false)
  const hiddenFileInput = useRef(null)
  const handleUpload = (event) => {
    event.preventDefault()
    hiddenFileInput.current.click()
  }
  const handleRemove = async (event) => {
    event.preventDefault()
    const token = localStorage.getItem('token')
    await fileUploader(
      user,
      `process/removeAttachment`,
      token,
      inputId,
      processId
    )
    setSelectedFile()
    setIsSelected(false)
  }
  const handleChange = async (event) => {
    const file = event.target.files[0]
    if (file) {
      const token = localStorage.getItem('token')
      await fileUploader(
        user,
        `process/attachment`,
        token,
        inputId,
        processId,
        file
      )
      setSelectedFile(file)
      setIsSelected(true)
    }
  }
  return (
    <div>
      <label>
        <small>{selectedFile?.name}</small>
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={handleChange}
          style={{ display: 'none' }}
        />
        <div>
          {isSelected ? (
            <button className='removeUpload' onClick={handleRemove}>
              <>Ta bort</>
            </button>
          ) : (
            <button className='uploadButton' onClick={handleUpload}>
              <>{buttonText}</> <img
                alt="add"
                className='addIcon'
                src={add}
              />
            </button>
          )}
        </div>
      </label>
    </div>
  )
}

export default FileUploadInput
