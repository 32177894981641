import { checkPnr } from '../../../helpers/formatters'

export const newProcessValidator = (values) => {
  const e = {}
  var percentArray = {
    leaveFromForsKassan: values.fromForsKassanPercent,
    otherParentLeave: values.otherParentLeavePercent,
    unionAssigment: values.unionAssigmentPercent,
    closePersonCare: values.closePersonCarePercent,
    militaryService: values.militaryServicePercent,
    study: values.studyPercent,
    permission: values.permissionPercent,
    leaveOfAbsence: values.leaveOfAbsencePercent,
  }

  for (const property in percentArray) {
    if (values[property]) {
      var percentage = percentArray[property]
      if (percentage) {
        if (isNaN(percentage)) {
          e[property] = 'Omfattning måste anges som ett heltal mellan 1-100'
        }
        else if (percentage < 1 || percentage > 100) {
          e[property] = 'Omfattning av ledighet ska vara mellan 1-100 %'
        }
      }
    }
  }
  if (!values.phoneNumberDuringLeave) {
    e.phoneNumberDuringLeave = 'Telefonnummer är obligatoriskt'
  }

  if (values.leaveFromForsKassan || values.otherParentLeave) {

    if (!values.kidPersonalNo && !values.estimatedBirthday) {
      e.estimatedBirthday =
        'Barnets personnummer eller beräknad nedkomst måste vara i fylld'
    }
  }

  /*   if (values.kidPersonalNo) {
      if (!checkPnr(values.kidPersonalNo)) {
        e.kidPersonalNo = 'Kontrollera personnumret'
      }
    } */

  if (values.leaveFromForsKassan) {
    if (
      !values.fromDateFromForsKassan ||
      !values.toDateFromForsKassan ||
      !values.fromForsKassanPercent
    ) {
      e.franForskassan =
        'Du måste fylla i datum och omfattning av ledighet i procent'
    }
    if (values.fromDateFromForsKassan > values.toDateFromForsKassan) {
      e.franForskassan = 'Datum stämmer inte'
    }
  }

  if (values.otherParentLeave) {
    if (
      !values.fromDateOtherParentLeave ||
      !values.toDateOtherParentLeave ||
      !values.otherParentLeavePercent
    ) {
      e.otherParentLeave =
        'Du måste fylla i datum och omfattning av ledighet i procent'
    }
    if (values.fromDateOtherParentLeave > values.toDateOtherParentLeave) {
      e.otherParentLeave = 'Datum stämmer inte'
    }
  }

  if (values.unionAssigment) {
    if (
      !values.fromDateUnionAssigment ||
      !values.toDateUnionAssigment ||
      !values.unionAssigmentPercent
    ) {
      e.unionAssigment =
        'Du måste fylla i datum och omfattning av ledighet i procent'
    }
    if (values.fromDateUnionAssigment > values.toDateUnionAssigment) {
      e.unionAssigment = 'Datum stämmer inte'
    }
  }

  if (values.closePersonCare) {
    if (
      !values.fromDateClosePersonCare ||
      !values.toDateClosePersonCare ||
      !values.closePersonCarePercent
    ) {
      e.closePersonCare =
        'Du måste fylla i datum och omfattning av ledighet i procent'
    }
    if (values.fromDateClosePersonCare > values.toDateClosePersonCare) {
      e.closePersonCare = 'Datum stämmer inte'
    }
  }

  if (values.militaryService) {
    if (
      !values.fromDateMilitaryService ||
      !values.toDateMilitaryService ||
      !values.militaryServicePercent
    ) {
      e.militaryService =
        'Du måste fylla i datum och omfattning av ledighet i procent'
    }
    if (values.fromDateMilitaryService > values.toDateMilitaryService) {
      e.militaryService = 'Datum stämmer inte'
    }
  }

  if (values.study) {
    if (!values.fromDateStudy || !values.toDateStudy || !values.studyPercent) {
      e.study = 'Du måste fylla i datum och omfattning av ledighet i procent'
    }
    if (values.fromDateStudy > values.toDateStudy) {
      e.study = 'Datum stämmer inte'
    }
  }

  if (values.permission) {
    if (
      !values.fromDatePermission ||
      !values.toDatePermission ||
      !values.permissionPercent
    ) {
      e.permission =
        'Du måste fylla i datum och omfattning av ledighet i procent'
    }
    if (values.fromDatePermission > values.toDatePermission) {
      e.permission = 'Datum stämmer inte'
    }
  }

  if (values.leaveOfAbsence) {
    if (
      !values.fromDateLeaveOfAbsence ||
      !values.toDateLeaveOfAbsence ||
      !values.leaveOfAbsencePercent
    ) {
      e.leaveOfAbsence =
        'Du måste fylla i datum och omfattning av ledighet i procent'
    }
    if (values.fromDateLeaveOfAbsence > values.toDateLeaveOfAbsence) {
      e.leaveOfAbsence = 'Datum stämmer inte'
    }
  }

  const errors = {}
  errors.phoneNumberDuringLeave = [e.phoneNumberDuringLeave]
    .filter((x) => !!x && x !== '')

  errors.parentalLeave = [e.kidPersonalNo, e.estimatedBirthday, e.leaveFromForsKassan, e.otherParentLeave]
    .filter((x) => !!x && x !== '')

  errors.otherLeave = [e.unionAssigment, e.closePersonCare, e.militaryService, e.study, e.permission, e.leaveOfAbsence]
    .filter((x) => !!x && x !== '')

  errors.submit = [e.submit]
    .filter(x => !!x && x !== '')

  const nrOfErrors = errors.parentalLeave.length + errors.otherLeave.length + errors.phoneNumberDuringLeave.length
  if (nrOfErrors == 0)
    errors.isValid = true
  return errors
}

export const existingProcessValidator = (values) => {
  const e = {}
  const errors = {}
  const nrOfErrors = 0
  if (nrOfErrors == 0) errors.isValid = true
  return errors
}
