
export const newProcessValidator = (values) => {
    const e = {}
    if (!values.emailOfNominee) {
        e.emailOfNominee = 'Välj en medarbetare'
    }
    if (!values.position) {
        e.position = 'Befattning är obligatoriskt'
    }

    if (values.hoursOvertimeWithdrawals1) {
        if (!values.hoursOvertimeWithdrawalsFrom1 || !values.hoursOvertimeWithdrawalsTo1 || !values.hoursOvertimeWithdrawalsReason1) {
            e.hoursOvertimeWithdrawals1 = 'Fyll i datum och orsak'
        }
    }
    if (values.hoursOvertimeWithdrawals2) {
        if (!values.hoursOvertimeWithdrawalsFrom2 || !values.hoursOvertimeWithdrawalsTo2 || !values.hoursOvertimeWithdrawalsReason2) {
            e.hoursOvertimeWithdrawals2 = 'Fyll i datum och orsak'
        }
    }
    if (values.hoursOvertimeWithdrawals3) {
        if (!values.hoursOvertimeWithdrawalsFrom3 || !values.hoursOvertimeWithdrawalsTo3 || !values.hoursOvertimeWithdrawalsReason3) {
            e.hoursOvertimeWithdrawals3 = 'Fyll i datum och orsak'
        }
    }
    if (values.hoursOvertimeWithdrawals4) {
        if (!values.hoursOvertimeWithdrawalsFrom4 || !values.hoursOvertimeWithdrawalsTo4 || !values.hoursOvertimeWithdrawalsReason4) {
            e.hoursOvertimeWithdrawals4 = 'Fyll i datum och orsak'
        }
    }
    if (values.hoursExtraOvertime1) {
        if (!values.hoursExtraOvertimeFrom1 || !values.hoursExtraOvertimeTo1 || !values.hoursExtraOvertimeReason1) {
            e.hoursExtraOvertime1 = 'Fyll i datum och orsak'
        }
    }
    if (values.hoursExtraOvertime2) {
        if (!values.hoursExtraOvertimeFrom2 || !values.hoursExtraOvertimeTo2 || !values.hoursExtraOvertimeReason2) {
            e.hoursExtraOvertime2 = 'Fyll i datum och orsak'
        }
    }
    if (values.hoursExtraOvertime3) {
        if (!values.hoursExtraOvertimeFrom3 || !values.hoursExtraOvertimeTo3 || !values.hoursExtraOvertimeReason3) {
            e.hoursExtraOvertime3 = 'Fyll i datum och orsak'
        }
    }
    if (values.hoursExtraOvertime4) {
        if (!values.hoursExtraOvertimeFrom4 || !values.hoursExtraOvertimeTo4 || !values.hoursExtraOvertimeReason4) {
            e.hoursExtraOvertime4 = 'Fyll i datum och orsak'
        }
    }

    const errors = {}
    errors.position = [e.position]
        .filter((x) => !!x && x !== '')

    errors.emailOfNominee = [e.emailOfNominee]
        .filter((x) => !!x && x !== '')

    errors.nomineeAndPosition = [e.emailOfNominee, e.position]
        .filter(x => !!x && x !== '')

    errors.hoursOvertimeWithdrawal = [e.hoursOvertimeWithdrawals1, e.hoursOvertimeWithdrawals2, e.hoursOvertimeWithdrawals3, e.hoursOvertimeWithdrawals4]
        .filter((x) => !!x && x !== '')

    errors.hoursExtraOvetime = [e.hoursExtraOvertime1, e.hoursExtraOvertime2, e.hoursExtraOvertime3, e.hoursExtraOvertime4]
        .filter((x) => !!x && x !== '')

    const nrOfErrors = errors.position.length + errors.hoursOvertimeWithdrawal.length + errors.hoursExtraOvetime.length

    if (nrOfErrors == 0)
        errors.isValid = true
    return errors
}

export const existingProcessValidator = (values) => {
    const e = {}
    const errors = {}
    const nrOfErrors = 0
    if (nrOfErrors == 0) errors.isValid = true
    return errors
}

