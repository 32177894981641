import React, { useEffect, useState } from 'react'
import Grid from '../../atoms/Grid/Grid'
import GridSingles from '../../atoms/Grid/GridSingles'
import InputTextArea from '../../molecules/InputTextArea/InputTextArea'
import InputText from '../../molecules/Input/InputText'
import Dropdown from '../../molecules/StyledSelect/Dropdown'
import { scrollToPos } from '../../../helpers/scrollToElement'
import { updateFormVals } from '../../../helpers/update'
import FileUploadInput from '../../molecules/FileUploadInput/FileUploadInput'

const SupportEmailForm = ({ formVals, setFormVals, errors, setErrors, setId }) => {
  const [update, setUpdate] = useState('init')
  const { v4: uuidv4 } = require('uuid')
  const [guid, setGuid] = useState()

  useEffect(() => {
    if (!formVals)
      return
    const e = {}
    if (!formVals?.mailHeader || !formVals?.mailContent) {
      e.support = 'Fälten är obligatoriska'
    }
    if (formVals.category === 'process' && !formVals.process) {
      e.support = 'Välj process'
    }

    if (Object.keys(e).length !== 0) {
      const firstErrorDiv = document.getElementById(Object.keys(e)[0])
      firstErrorDiv && scrollToPos(firstErrorDiv)
    }
    else
      e.isValid = true
    setErrors(e)
  }, [formVals])

  useEffect(() => {
    if (update === 'init') {
      return;
    }

    updateFormVals(update, formVals, setFormVals);
  }, [update]);

  useEffect(() => {
    if (update === 'init' || update.id !== 'category' || update.value === 'process') {
      return;
    }

    setFormVals((prevFormVals) => ({ ...prevFormVals, process: null }));
  }, [update]);

  useEffect(() => {
    setGuid(uuidv4())
  }, [])

  useEffect(() => {
    setId(guid)
  }, [guid])

  return (
    <>
      <Grid title={"Felrapportering"}>
        <Dropdown
          label={"Kategori"}
          options={[
            { value: "process", label: "Process" },
            { value: "general", label: "Allmänt" },
            { value: "improvement", label: "Förbättringsförslag" },
            { value: "other", label: "Övrigt" }
          ]}
          id={"category"}
          selectedValue={formVals.process ? "process" : formVals.category}
          onSelect={(e) => { setUpdate({ id: "category", value: e }) }}
        />
        {(formVals.category === 'process' || formVals.process) && (
          <Dropdown
            label={"Process"}
            options={[
              { value: "vip", label: "VIP" },
              { value: "vehicle", label: "Bilbeställning" },
              { value: "leaveApplication", label: "Ledighetsansökan" },
              { value: "recruiting", label: "Rekryteringsbonus" },
              { value: "overtime", label: "Extra övertid" },
              { value: "suggestionBox", label: "Förslagslåda" },
              { value: "bonus", label: "Certifieringsbonus" }
            ]}
            id={"process"}
            selectedValue={formVals.process}
            onSelect={(e) => { setUpdate({ id: "process", value: e }) }}
          />
        )}
      </Grid>

      <InputText
        id={'mailHeader'}
        label={"Rubrik"}
        text={formVals.mailHeader}
        onChange={(e) => {
          setUpdate({ id: "mailHeader", value: e })
        }}
        disabled={false}
      />
      <GridSingles errorMsgState={errors.support}>
        <InputTextArea
          id={'mailContent'}
          title={"Meddelande"}
          onChange={(e) => {
            setUpdate({ id: "mailContent", value: e })
          }}
          text={formVals.mailContent}
        />
      </GridSingles>
      <GridSingles>
        <FileUploadInput buttonText={"Lägg till fil"} inputId={"support"} processId={guid} />
      </GridSingles>
    </>
  )
}

export default SupportEmailForm