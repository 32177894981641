import React, { useContext, useEffect, useState } from "react";
import Grid from "../../atoms/Grid/Grid";
import GridSingles from "../../atoms/Grid/GridSingles";
import InputText from "../../molecules/Input/InputText";
import Dropdown from "../../molecules/StyledSelect/Dropdown";
import InputTextArea from "../../molecules/InputTextArea/InputTextArea";
import { requestMaker } from "../../../helpers/requestMaker";
import { SimpleAuthContext } from "../../../helpers/SimpleAuthProvider";
import { existingProcessValidator, newProcessValidator } from "./validator";
import CheckBox from "../../atoms/Check/CheckBox";
import GridQuintuplets from "../../atoms/Grid/GridQuintuplets";
import { updateFormVals } from "../../../helpers/update";



const VehicleForm = ({
  formVals,
  setFormVals,
  errors,
  setErrors,
  disabled = false,
}) => {
  var { user } = useContext(SimpleAuthContext);
  const [update, setUpdate] = useState("init");
  useEffect(() => {
    if (!formVals) return;
    var e;
    if (!disabled) e = newProcessValidator(formVals);
    else e = existingProcessValidator(formVals);
    setErrors(e);
  }, [formVals]);

  useEffect(() => {
    if (update === "init") {
      if (formVals.gasStations === null) {
        setUpdate({
          id: "gasStations",
          value: {
            St1: false,
            CircleK: false,
            Shell: false,
            Okq8: false,
            Preem: false,
            "Flyttas från registreringsnummer": false,
          },
        });
        return;
      }
    }
  
    var vals = { ...formVals };
  
    if (["category", "netPrice"].includes(update.id)) {
      vals[update.id] = update.value;
  
      const getMaxPrice = async () => {
        const token = localStorage.getItem("token");
        const dataFromEvaluate = await requestMaker(
          user,
          "POST",
          "process/evaluate/vehicle",
          token,
          vals
        );
  
        setFormVals({
          ...formVals,
          [update.id]: update.value,
          maxPrice: dataFromEvaluate.maxPrice,
          nettoSalaryReduction: dataFromEvaluate.nettoSalaryReduction,
        });
      };
  
      getMaxPrice();
    } else if (update.id === "gasStations") {
      let updatedGasStations;
  
      if (update.value["Flyttas från registreringsnummer"] === true) {
        updatedGasStations = { "Flyttas från registreringsnummer": true };
        Object.keys(formVals.gasStations).forEach(key => {
          if (key !== "Flyttas från registreringsnummer") {
            updatedGasStations[key] = false;
          }
        });
      } else {
        updatedGasStations = { ...formVals.gasStations, ...update.value };
      }
  
      setFormVals({ ...formVals, gasStations: updatedGasStations });
    } else {
      updateFormVals(update, formVals, setFormVals);
    }
  }, [update]);

  useEffect(() => {
    if(formVals.category)
    {
    const timer = setTimeout(() => {
      setUpdate({ id: "netPrice", value: formVals.netPrice });
    }, 2000);
    return () => clearTimeout(timer);
    }
  }, [formVals.netPrice])
  

  return (
    <>
      <Grid
        title={"Brukare och Fordon"}
        errorMsgState={errors.car}
        disabled={disabled}
      >
        <InputText
          label={"Brukarens namn"}
          text={formVals.companyName ?? ""}
          id={"companyName"}
        />
        <InputText
          label={"Organisationsnr"}
          text={formVals.organizationNo ?? ""}
          id={"organizationsNo"}
        />
        <InputText
          label={"Filialnummer"}
          text={formVals.branchNo ?? ""}
          id={"branchNo"}
        />
        <InputText
          label={"Försäkringsbolag"}
          text={formVals.insuranceCompany ?? ""}
          id={"insuranceCompany"}
        />
        <InputText
          label={"Bilmärke"}
          text={formVals.vehicleBrand ?? ""}
          id={"vehicleBrand"}
          onChange={(e) => {
            setUpdate({ id: "vehicleBrand", value: e });
          }}
          disabled={false}
        />
        <InputText
          label={"Modell"}
          text={formVals.vehicleModel ?? ""}
          id={"vehicleModel"}
          onChange={(e) => {
            setUpdate({ id: "vehicleModel", value: e });
          }}
          disabled={false}
        />
        <InputText
          label={"Nettopris offert"}
          text={formVals.netPrice ?? ""}
          id={"netPrice"}
          onChange={(e) => {
            var v;
            if (e) v = isNaN(e) ? e : parseInt(e);
            setFormVals({
              ...formVals,
              netPrice: v,
            });
          }}
          onBlur={ formVals.category != null ? () => setUpdate({ id: "netPrice", value: formVals.netPrice }) : undefined }
          disabled={false}
        />
        <InputText
          label={"CO2"}
          text={formVals.co2 ?? ""}
          id={"co2"}
          onChange={(e) => {
            var v;
            if (e) v = isNaN(e) ? e : parseInt(e);
            setUpdate({ id: "co2", value: v });
          }}
          disabled={false}
        />
      </Grid>
      <Grid
        title={"Tillhörighet"}
        errorMsgState={errors.catAndCostArea}
        disabled={disabled}
      >
        {!disabled ? (
          <Dropdown
            label={"Kategori"}
            options={[
              { value: "category3", label: "Kategori 3" },
              { value: "category4", label: "Kategori 4" },
              "Poolbil",
              "Personalbil",
              "Service car",
            ]}
            id={"category"}
            onSelect={(e) => {
              setUpdate({ id: "category", value: e });
            }}
          />
        ) : (
          <InputText
            label={"Kategori"}
            text={formVals.category ?? ""}
            id={"category"}
          />
        )}
        <InputText
          label={"Kostnadställe"}
          text={formVals.costArea ?? ""}
          id={"costArea"}
          onChange={(e) => {
            setUpdate({ id: "costArea", value: e });
          }}
          disabled={false}
        />
        <InputText
          label={"Maxpris"}
          text={formVals.maxPrice ?? ""}
          id={"maxPrice"}
        />
        <div />
      </Grid>
      <Grid title={"Förare"} errorMsgState={errors.driver} disabled={disabled}>
        <InputText
          label={"Förarens namn"}
          text={formVals.driverName ?? ""}
          id={"driverName"}
          onChange={(e) => {
            setUpdate({ id: "driverName", value: e });
          }}
        />
        <InputText
          label={"Personnummer (10 siffror)"}
          text={formVals.personalNo ?? ""}
          id={"personalNo"}
          onChange={(e) => {
            setUpdate({ id: "personalNo", value: e });
          }}
          disabled={false}
        />
        <InputText
          label={"Adress"}
          text={formVals.address ?? ""}
          id={"address"}
          onChange={(e) => {
            setUpdate({ id: "address", value: e });
          }}
          disabled={false}
        />
        <InputText
          label={"Mobil"}
          text={formVals.mobilePhone ?? ""}
          id={"mobilePhone"}
          onChange={(e) => {
            setUpdate({ id: "mobilePhone", value: e });
          }}
          disabled={false}
        />
        <InputText
          label={"Postnummer"}
          text={formVals.zipCode ?? ""}
          id={"zipCode"}
          onChange={(e) => {
            setUpdate({ id: "zipCode", value: e });
          }}
          disabled={false}
        />
        <InputText
          label={"E-post"}
          text={formVals.email ?? ""}
          id={"email"}
          onChange={(e) => {
            setUpdate({ id: "email", value: e });
          }}
        />
        <InputText
          label={"Ort"}
          text={formVals.city ?? ""}
          id={"city"}
          onChange={(e) => {
            setUpdate({ id: "city", value: e });
          }}
          disabled={false}
        />
        <InputText
          label={"Beräknad körsträcka i mil/år: Tjänsteresor"}
          text={formVals.expectedMiles ?? ""}
          id={"expectedMiles"}
          onChange={(e) => {
            setUpdate({ id: "expectedMiles", value: e });
          }}
          disabled={false}
        />
        <InputText
          label={"Anställningsnummer/kod"}
          text={formVals.employeeNo ?? ""}
          id={"employeeNo"}
        />
        <InputText
          label={"Privata resor i mil/år"}
          text={formVals.privateMiles ?? ""}
          id={"privateMiles"}
          onChange={(e) => {
            setUpdate({ id: "privateMiles", value: e });
          }}
          disabled={false}
        />
        <InputText
          label={"Beställarens chef mailadress"}
          text={formVals.managerEmail ?? ""}
          id={"managerEmail"}
        />
      </Grid>
      <GridSingles
        title={"Nettolöneavdrag (anges av biladministrationen)"}
        disabled={disabled}
      >
        <InputText
          label={"Kr/månad"}
          text={Math.floor(formVals.nettoSalaryReduction)}
          id={"nettoSalaryReduction"}
        />
      </GridSingles>
      <GridQuintuplets
        title={"Drivmedelskort och registreringsnummer"}
        errorMsgState={errors.fuelCard}
        disabled={disabled}
      >
        <>
          <CheckBox
            label="ST1"
            value={"st1"}
            isChecked={formVals.gasStations?.St1 === true}
            disabled={
              disabled ||
              formVals.gasStations?.["Flyttas från registreringsnummer"]
            }
            onChange={(e) =>
              setUpdate({ id: "gasStations", value: { St1: e.target.checked } })
            }
          />
          <CheckBox
            label="CircleK"
            value={"circleK"}
            isChecked={formVals.gasStations?.CircleK === true}
            disabled={
              disabled ||
              formVals.gasStations?.["Flyttas från registreringsnummer"]
            }
            onChange={(e) =>
              setUpdate({
                id: "gasStations",
                value: { CircleK: e.target.checked },
              })
            }
          />
          <CheckBox
            label="Shell"
            value={"shell"}
            isChecked={formVals.gasStations?.Shell === true}
            disabled={
              disabled ||
              formVals.gasStations?.["Flyttas från registreringsnummer"]
            }
            onChange={(e) =>
              setUpdate({
                id: "gasStations",
                value: { Shell: e.target.checked },
              })
            }
          />
          <CheckBox
            label="OKQ8"
            value={"okq8"}
            isChecked={formVals.gasStations?.Okq8 === true}
            disabled={
              disabled ||
              formVals.gasStations?.["Flyttas från registreringsnummer"]
            }
            onChange={(e) =>
              setUpdate({
                id: "gasStations",
                value: { Okq8: e.target.checked },
              })
            }
          />
          <CheckBox
            label="Preem"
            value={"preem"}
            isChecked={formVals.gasStations?.Preem === true}
            disabled={
              disabled ||
              formVals.gasStations?.["Flyttas från registreringsnummer"]
            }
            onChange={(e) =>
              setUpdate({
                id: "gasStations",
                value: { Preem: e.target.checked },
              })
            }
          />
          <CheckBox
            label="Flyttas från registreringsnummer"
            value={"Flyttas från registreringsnummer"}
            isChecked={
              formVals.gasStations?.["Flyttas från registreringsnummer"] ===
              true
            }
            disabled={disabled}
            onChange={(e) =>
              setUpdate({
                id: "gasStations",
                value: { "Flyttas från registreringsnummer": e.target.checked },
              })
            }
          />
          {formVals.gasStations?.["Flyttas från registreringsnummer"] ===
            true && (
            <InputText
              label={"Registreringsnummer"}
              text={formVals.carRegNo ?? ""}
              id={"carRegNo"}
              onChange={(e) => {
                setUpdate({ id: "carRegNo", value: e });
              }}
              disabled={disabled}
            />
          )}
        </>
      </GridQuintuplets>
      <Grid
        title={"Leverantör"}
        errorMsgState={errors.supplier}
        disabled={disabled}
      >
        <InputText
          label={"Leverantörens namn"}
          text={formVals.supplierName ?? ""}
          id={"supplierName"}
          onChange={(e) => {
            setUpdate({ id: "supplierName", value: e });
          }}
          disabled={false}
        />
        <InputText
          label={"Kontakt"}
          text={formVals.supplierContact ?? ""}
          id={"supplierContact"}
          onChange={(e) => {
            setUpdate({ id: "supplierContact", value: e });
          }}
          disabled={false}
        />
        <InputText
          label={"E-post"}
          text={formVals.supplierEmail ?? ""}
          id={"supplierEmail"}
          onChange={(e) => {
            setUpdate({ id: "supplierEmail", value: e });
          }}
          disabled={false}
        />
        <InputText
          label={"Telefon"}
          text={formVals.supplierPhone ?? ""}
          id={"supplierPhone"}
          onChange={(e) => {
            setUpdate({ id: "supplierPhone", value: e });
          }}
          disabled={false}
        />
      </Grid>
      <GridSingles
        title={"Bilagor (Offert, Personalbilskalkyl, Personalbilsavtal)"}
        disabled={disabled}
      >
        <InputTextArea
          title={"Kommentar"}
          id={"commentField"}
          onChange={(e) => {
            setUpdate({ id: "commentField", value: e });
          }}
          text={formVals.commentField ?? ""}
        />
      </GridSingles>
    </>
  );
};

export default VehicleForm;
