import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import VipExisting from '../../organisms/VipExisting/VipExisting'
import VipNew from '../../organisms/VipNew/VipNew'

const VipProcess = ({ setTitle }) => {
    const { id, draft } = useParams()

    useEffect(() => {
        setTitle('VIP - Value In People')
    }, [])

    return (
        id ?
            <VipExisting id={id} isDraft={draft} />
            :
            <VipNew />
    )
}

export default VipProcess