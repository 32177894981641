import React, { useContext, useEffect, useState } from 'react'
import formatDate from '../../../helpers/formatters'
import Grid from '../../atoms/Grid/Grid'
import GridSingles from '../../atoms/Grid/GridSingles'
import InputTextArea from '../../molecules/InputTextArea/InputTextArea'
import InputText from '../../molecules/Input/InputText'
import { scrollToPos } from '../../../helpers/scrollToElement'
import InformationButton from '../../molecules/InformationButton/InformationButton'
import { updateFormVals } from '../../../helpers/update'

const SuggestionForm = ({ formVals, setFormVals, errors, setErrors, disabled = false }) => {
    const [update, setUpdate] = useState('init')

    useEffect(() => {
        if (!formVals)
            return
        const e = {}
        if (!formVals?.suggestionHeader || !formVals?.suggestion) {
            e.suggestions = 'Fälten är obligatoriska'
        }

        if (Object.keys(e).length !== 0) {
            const firstErrorDiv = document.getElementById(Object.keys(e)[0])
            firstErrorDiv && scrollToPos(firstErrorDiv)
        }
        else
            e.isValid = true
        setErrors(e)
    }, [formVals])

    useEffect(() => {
        if (update == 'init')
            return

        updateFormVals(update, formVals, setFormVals)
    }, [update])

    return (
        <>
            {/* <InformationButton /> */}
            <br />
            <Grid title={"Förslag"} disabled={disabled} >
                <InputText
                    id={'email'}
                    label={"Epost"}
                    text={formVals.email ?? ''}
                />
                <InputText
                    id={'id'}
                    label={"Datum"}
                    text={formatDate(formVals.createdAt ?? '')}
                />
                <InputText
                    id={'employmentNumber'}
                    label={"Anställningsnr"}
                    text={formVals.employmentNumber ?? ''}
                />
            </Grid>
            <InputText
                id={'suggestionHeader'}
                label={"Rubrik"}
                text={formVals.suggestionHeader}
                onChange={(e) => {
                    setUpdate({ id: "suggestionHeader", value: e })
                }}
                disabled={disabled}
            />
            <GridSingles disabled={disabled} errorMsgState={errors.suggestions}>
                <InputTextArea
                    id={'suggestion'}
                    title={"Förslag"}
                    onChange={(e) => { setUpdate({ id: "suggestion", value: e }) }}
                    text={formVals.suggestion}
                />
            </GridSingles>

        </>
    )
}

export default SuggestionForm