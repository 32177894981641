import { useRef, useState, useContext, useEffect } from 'react'
import './Attachment.css'
import FileUploadInput from '../FileUploadInput/FileUploadInput'
import { requestMaker, downloader, remover } from '../../../helpers/requestMaker'
import { SimpleAuthContext } from '../../../helpers/SimpleAuthProvider'
import Button from '../../atoms/Button/Button'

const Attachment = ({
  inputId,
  processId,
  attachments,
  allowRemove,
  handleRemoveExistingAttachment,
}) => {
  const { user } = useContext(SimpleAuthContext)

  const downloadAttachment = async (inputId) => {
    const attachmentId = attachments?.find((attach) => {
      return attach.filename?.endsWith(inputId)
    }).id
    const token = localStorage.getItem('token')
    const result = await downloader(user, `attachment/${attachmentId}`, token)
    const url = window.URL.createObjectURL(new Blob([result.blob]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', result.fileName)
    link.click()
  }

  const removeAttachment = async (inputId) => {
    const attachmentId = attachments?.find((attach) => {
      return attach.filename?.endsWith(inputId)
    }).id
    const token = localStorage.getItem('token')
    const result = await remover(user, `attachment/${attachmentId}`, token)
  }

  return attachments == null ||
    !attachments?.some((item) => item?.filename?.endsWith('@' + inputId)) ? (
    <FileUploadInput
      labelText="Bifoga dokument"
      buttonText="Ladda upp"
      inputId={inputId}
      processId={processId}
    />
  ) : attachments?.some((item) => item?.filename?.endsWith('@' + inputId)) ? (
    <div>
      <Button
        text={inputId}
        onClick={async (e) => {
          e.preventDefault()
          await downloadAttachment('@' + inputId)
        }}
        overrideStyles={true}
        stylesFromParent='attachmentButton'
      />

      {allowRemove && (
        <Button
          id={inputId}
          text="Ta bort fil"
          onClick={async (e) => {
            e.preventDefault()
            await removeAttachment('@' + inputId)
            handleRemoveExistingAttachment(e)
          }}
          overrideStyles={true}
          stylesFromParent='attachmentButton'
        />
      )}
    </div>
  ) : (
    ''
  )
}

export default Attachment
