const formatDate = (date) => {
  try {
    return date?.slice(0, 10)
  } catch (error) {
    console.log(error)
  }
}
//Using SamLogic to validate pnr.
export const checkPnr = (s) => {
  if (!/^\d{10}$/.test(s)) {
    return false
  }
  return (
    s
      .split('')
      .map(Number)
      .reduce((acc, curr, index) => {
        const multiplier = index % 2 === 0 ? 2 : 1
        const product = curr * multiplier
        const sum = (product % 10) + Math.floor(product / 10)
        return acc + sum
      }, 0) %
    10 ===
    0
  )
}

export const formatDecisionName = (decisionName) => {
  var formattedName
  switch (decisionName) {
    case 'lineManager':
    case 'manager':
      formattedName = 'Närmsta chef'
      break
    case 'user':
      formattedName = 'Initierad av'
      break
    case 'carAdmin':
    case 'car_admin':
      formattedName = 'Biladministratör'
      break
    case 'hr':
      formattedName = 'HR'
      break
    case 'finance':
      formattedName = 'Finance'
      break
    case 'vd':
      formattedName = 'VD'
      break
    case 'union':
      formattedName = 'Facklig representant'
      break
    default:
      formattedName = decisionName?.charAt(0).toUpperCase() + decisionName?.slice(1)
  }

  return formattedName
}

export default formatDate
