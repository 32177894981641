import { useState } from 'react'
import Modal from '../../atoms/Modal/Modal'

const InformationButton = ({ buttonText, informationText }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <button type="button" onClick={() => setIsOpen(!isOpen)}>
        {buttonText}
      </button>
      <Modal open={isOpen}>
        <br />
        {informationText}
      </Modal>
      <br />
    </div>
  );
};

export default InformationButton;
