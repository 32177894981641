import React, { useContext, useEffect, useState } from 'react'
import formatDate from '../../../helpers/formatters'
import Grid from '../../atoms/Grid/Grid'
import GridSingles from '../../atoms/Grid/GridSingles'
import InputTextArea from '../../molecules/InputTextArea/InputTextArea'
import InputText from '../../molecules/Input/InputText'
import { scrollToPos } from '../../../helpers/scrollToElement'
import InformationButton from '../../molecules/InformationButton/InformationButton'
import DropdownSearch from '../../molecules/StyledSelect/DropdownSearch'
import { requestMaker } from '../../../helpers/requestMaker'
import { SimpleAuthContext } from '../../../helpers/SimpleAuthProvider'

import { updateFormVals } from '../../../helpers/update'

const RecruitingForm = ({ formVals, setFormVals, errors, setErrors, disabled = false, stepName }) => {
  const [update, setUpdate] = useState('init')
  const [searchString, setSearchString] = useState()
  const [options, setOptions] = useState([])
  var { user } = useContext(SimpleAuthContext);

  useEffect(() => {
    const token = localStorage.getItem('token')
    async function fetcher() {
      let data = await requestMaker(
        user,
        'POST',
        "employee/searchemployees",
        token,
        searchString
      )
      setOptions(data)
    }

    if (!!searchString && !['', formVals.nameOfNominee].includes(searchString))
      fetcher()

  }, [searchString])


  useEffect(() => {
    if (!formVals)
      return
    const e = {}
    if (!formVals?.recruiterEmail) {
      e.recruiterEmail = 'E-mail för rekryterare är obligatoriskt'
    }
    if (!formVals?.phoneNo) {
      e.phoneNo = 'Telefonnummer är obligatoriskt'
    }
    if (!formVals?.candidateName) {
      e.candidateName = 'Namn är obligatoriskt'
    }
    if (!formVals?.candidatePhone) {
      e.candidatePhone = 'Telefonnummer är obligatoriskt'
    }
    if (!formVals?.candidateEmail) {
      e.candidateEmail = 'E-mail är obligatoriskt'
    }
    if (!formVals?.jobTitle) {
      e.jobTitle = 'obligatoriskt'
    }
    if (!formVals?.howYouKnowCandidate) {
      e.howYouKnowCandidate = 'obligatoriskt'
    }
    if (Object.keys(e).length !== 0) {
      const firstErrorDiv = document.getElementById(Object.keys(e)[0])
      firstErrorDiv && scrollToPos(firstErrorDiv)
    }
    else
      e.isValid = true
    setErrors(e)
  }, [formVals])

  useEffect(() => {
    if (update == 'init') {
      if (!formVals.recruiterEmail) setFormVals({ ...formVals, recruiterEmail: user.email })
      return
    }
    updateFormVals(update, formVals, setFormVals)
  }, [update])
  return (
    <>
      <InformationButton
        buttonText={"Information om Finders Fee – Rekryteringsbonus"}
        informationText={`
                                Tips som leder till att en person erhåller en prov-/tillsvidareanställning
                                ger dig som tipsare möjlighet att ansöka om Finders Fee/Rekryteringsbonus.
                                Finders Fee/Rekryteringsbonus utbetalas 6 månader efter att personen påbörjat
                                sin anställning.Observera att en Finders Fee/Rekryteringsbonus utbetalas endast
                                om personen vid utbetalningstillfället fortfarande är anställd och inte har
                                sagt upp sig. Beloppet kan variera mellan 5000kr och 20 000kr brutto och
                                utbetalas som ett engångsbelopp. Beloppsnivån beslutas utifrån ett antal
                                kriterier som rekryterande chef bedömer i samråd med HR.`}
      />

      <br />
      <Grid title={"Anställd som ansöker om rekryteringsbonus"} errorMsgState={errors.recruiterEmail}>
        {disabled ?
          <InputText
            label={"Epost"}
            text={formVals.recruiterEmail}
          />
          :
          <DropdownSearch
            label={"Namn"}
            defaultValue={{ value: user, label: user.firstName + ' ' + user.lastName }}
            searchString={searchString}
            setSearchString={(e) => { setSearchString(e) }}
            options={options}
            placeholder={"Välj namn"}
            onSelect={(e) => {
              e ? setUpdate({ id: "recruiterEmail", value: e.email })
                : setUpdate({ id: "recruiterEmail", value: null })

            }}
          />
        }
        <InputText
          id={'id'}
          label={"Datum"}
          text={formatDate(formVals.createdAt) ?? ''}
        />
        <InputText
          id={'phoneNo'}
          label={"Telefonnummer"}
          text={formVals.phoneNo}
          onChange={(e) => {
            setUpdate({ id: "phoneNo", value: e })
          }}
          disabled={disabled}
          validationMsg={errors.phoneNo}
        />
        {stepName?.currentStep >= 2 && <InputText
          id={'amount'}
          label={"Belopp"}
          text={formVals.amount}
          onChange={(e) => {
            setUpdate({ id: "amount", value: e })
          }}
          disabled={stepName?.currentStep !== 2}
        />}

      </Grid>
      <Grid title={"Bonus gäller rekrytering av följande medarbetare"} disabled={disabled} >
        <InputText
          id={'candidateName'}
          label={"Namn"}
          text={formVals.candidateName}
          onChange={(e) => {
            setUpdate({ id: "candidateName", value: e })
          }}
          disabled={disabled}
          validationMsg={errors.candidateName}
        />
        <InputText
          id={'candidatePhone'}
          label={"Telefonnummer"}
          text={formVals.candidatePhone}
          onChange={(e) => {
            setUpdate({ id: "candidatePhone", value: e })
          }}
          disabled={disabled}
          validationMsg={errors.candidatePhone}
        />
        <InputText
          id={'candidateEmail'}
          label={"E-mail address"}
          text={formVals.candidateEmail}
          onChange={(e) => {
            setUpdate({ id: "candidateEmail", value: e })
          }}
          disabled={disabled}
          validationMsg={errors.candidateEmail}
        />
      </Grid>

      <GridSingles disabled={disabled}>
        <InputTextArea
          id={'jobTitle'}
          title={"Till följande tjänst (Med referensnummer om sådant finns)"}
          onChange={(e) => {
            setUpdate({ id: "jobTitle", value: e })
          }}
          text={formVals.jobTitle}
          validationMsg={errors.jobTitle}
        />
        <InputTextArea
          id={'howYouKnowCandidate'}
          title={"Hur känner du kandidaten?"}
          onChange={(e) => {
            setUpdate({ id: "howYouKnowCandidate", value: e })
          }}
          text={formVals.howYouKnowCandidate}
          validationMsg={errors.howYouKnowCandidate}
        />
        <InputTextArea
          id={'otherInfo'}
          title={"Övriga upplysningar"}
          onChange={(e) => {
            setUpdate({ id: "otherInfo", value: e })
          }}
          text={formVals.otherInfo}
        />
      </GridSingles>

    </>
  )
}

export default RecruitingForm