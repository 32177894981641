import StepIndicator from '../../molecules/StepIndicator/StepIndicator'
import LoadingSpinner from '../../atoms/LoadingSpinner/LoadingSpinner'
import './BaseForm.css'

const BaseForm = ({
  forwardRef,
  modalIsOpen,
  modalMessage,
  children,
  stepIndicatorsProps,
  isLoading,
  setModalIsOpen
}) => {


  const closeModalAndRedirect = () => {
    setModalIsOpen(false)
    window.location.reload()
  }



  return (
    <>
      {stepIndicatorsProps &&
        <StepIndicator stepIndicatorsProps={stepIndicatorsProps} />
      }
      {isLoading ? (
        <div className='modalBackground'>
          <div className='loadingModal'>
            <LoadingSpinner />
          </div>
        </div>
      ) : modalIsOpen ? (
        <div className='modalBackground'>
        
          <div className='popupModal'>
          <div className='closePopupModal' onClick={() => closeModalAndRedirect()}>X</div>
          {modalMessage}</div>
        </div>
      ) : (
        <div className='formCard'>
          <form ref={forwardRef} className='form'>
            {children}
          </form>
        </div>
      )}
    </>
  )
}

export default BaseForm
