import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import VehicleExisting from '../../organisms/VehicleExisting/VehicleExisting'
import VehicleNew from '../../organisms/VehicleNew/VehicleNew'

const VehicleProcess = ({ setTitle }) => {
    const { id, draft } = useParams()

    useEffect(() => {
        setTitle('Bilansökan')
    }, [])

    return (
        id ?
            <VehicleExisting id={id} isDraft={draft} />
            :
            <VehicleNew />
    )
}

export default VehicleProcess