import React, { Children, cloneElement } from 'react'
import Grid from './Grid'

const GridQuintuplets = ({ title = undefined, errorMsgState = undefined, children, disabled = false }) => {


    return (
        <Grid
            title={title}
            gridTemplateColumnsCss={"20% 20% 20% 20% 20%"}
            disabled={disabled}
            errorMsgState={errorMsgState}
        >
            {children}
        </Grid>
    )
}

export default GridQuintuplets