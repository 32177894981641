import ProcessList from './ProcessList'

const ProcessListAllCompleted = () => {
  return (
    <div className='multi_processList'>
      <h1 className='processList_row--header'>GODKÄNDA</h1>
      <ProcessList endpoint={'Processes/UsersFinished/'} process={"completed"} filter={{ isDeclined: false }} />
      <h1 className='processList_row--header'>AVSLAGNA</h1>
      <ProcessList endpoint={'Processes/UsersFinished/'} process={"completed"} filter={{ isDeclined: true }} />
    </div>
  )
}

export default ProcessListAllCompleted
