import DecisionMaker from "../assets/DecisionMaker";
import { formatDecisionName } from '../helpers/formatters'

export function isStep(dataFetch, decisionMaker) {
  return (
    dataFetch?.processDecision?.pendingDecisionStep?.decisionMaker === decisionMaker
  );
}
export function isCompleted(dataFetch) {
  return dataFetch?.processDecision?.isFinished || dataFetch?.processDecision?.isDeclined
}
export function isFurtherInfoStep(dataFetch) {
  if (!dataFetch)
    return false
  if (dataFetch.decisions.length > 0)
    return dataFetch.processDecision?.decisions[dataFetch.processDecision.decisions.length - 1]?.type === 3
}

export const stepIndicatorsProps = (dataFetch) => {
  return {
    totalSteps: dataFetch?.decisionSteps?.length,
    currentStep:
      dataFetch?.processDecision?.isFinished
        ? dataFetch?.decisionSteps?.length
        : dataFetch?.processDecision?.isDeclined
          ? (dataFetch?.decisionSteps?.length > 0
            ? dataFetch?.decisionSteps.indexOf(
              dataFetch?.decisionSteps.find(
                (item) =>
                  item.id ===
                  dataFetch?.processDecision?.pendingDecisionStep?.id
              )
            ) + 0
            : 1)
          : (dataFetch?.decisionSteps?.length > 0
            ? dataFetch?.decisionSteps.indexOf(
              dataFetch?.decisionSteps.find(
                (item) =>
                  item.id ===
                  dataFetch?.processDecision?.pendingDecisionStep?.id
              )
            ) + 1
            : 1),
    stepName:
      dataFetch?.decisionSteps?.length > 0
        ? [...dataFetch?.decisionSteps].map((item) => getDecisionMakerFromId(item.id))
        : [],
  }
}

export const decisionStepIds = {
  lineManager: "ba635277-c37c-4727-a5d8-5853649c8e77",
  hr: "8253c74b-5880-4d5a-b189-dbd54a454746",
  finance: "445648be-026d-486d-8fb5-058f6a35068d",
  carAdmin: "6716f4a1-732e-41ea-ac22-60a9497765fd",
  vd: "57f1e908-336e-4cea-a379-07a72c974a88",
  payroll: "9f7a2a19-e4f5-425c-b4c8-c7794b229b2e",
  user: "bb68e50e-b586-4e47-a3b6-3384518af600",
  union: "2590e871-92c1-4d9b-83ff-6c8e86f9a3b2"
}

export const getDecisionMakerFromId = (id) => {
  return (Object.keys(decisionStepIds).find(key => decisionStepIds[key] === id))
}

export const getDecisionMakerFromEnum = (num) => {
  for (const key in DecisionMaker) {
    if (DecisionMaker[key] === num) {
      return key.toLowerCase()
    }
  }
  return 'unknown'
}

export const containsOldCategories = (f) => {
  return (
    f.nominationCategories &&
    (
      f.nominationCategories["Leverera oklanderlig service"] === true ||
      f.nominationCategories["Sätta kunden först"] === true ||
      f.nominationCategories["Fokusera på tillväxt och vinst"] === true ||
      f.nominationCategories["Skapa sin egen framgång"] === true
    )
  );
};
export const optionsArrayForDropDown = (dataFetch) => {
  const options = []
  const stepIndicator = stepIndicatorsProps(dataFetch)
  const stepsDropDown = stepIndicator?.stepName?.slice(0, stepIndicator.currentStep - 1)
  stepsDropDown.forEach(item => {
    options.push({ value: item, label: formatDecisionName(item) })
  });
  return options;
};
