import * as React from 'react'
import { SimpleAuthContext } from './SimpleAuthProvider'

const instance = {}

export const useMsal = () => {
  const { user } = React.useContext(SimpleAuthContext)
  return {
    accounts: [
      user == null
        ? null
        : {
          name: user.lastName + ', ' + user.firstName,
          email: user.email,
        },
    ],
    instance,
  }
}
