import React, { useContext, useEffect, useState } from 'react'
import formatDate from '../../../helpers/formatters'
import Grid from '../../atoms/Grid/Grid'
import GridSingles from '../../atoms/Grid/GridSingles'
import GridQuadruple from '../../atoms/Grid/GridQuadruple'
import InputTextArea from '../../molecules/InputTextArea/InputTextArea'
import InputText from '../../molecules/Input/InputText'
import InputDate from '../../molecules/Input/InputDate'
import DropdownSearch from '../../molecules/StyledSelect/DropdownSearch'
import { requestMaker } from '../../../helpers/requestMaker'
import { SimpleAuthContext } from '../../../helpers/SimpleAuthProvider'
import { existingProcessValidator, newProcessValidator } from './validator'
import { handleFormUpdate, updateFormVals } from '../../../helpers/update'
import InformationButton from '../../molecules/InformationButton/InformationButton'

const OvertimeForm = ({ formVals, setFormVals, errors, setErrors, disabled = false }) => {
    const [searchString, setSearchString] = useState(formVals.nameOfNominee ?? '')
    const [update, setUpdate] = useState('init')
    const [options, setOptions] = useState([])
    var { user } = useContext(SimpleAuthContext);

    useEffect(() => {
        const token = localStorage.getItem('token')
        async function fetcher() {
            let data = await requestMaker(
                user,
                'POST',
                "employee/searchemployees",
                token,
                searchString
            )
            setOptions(data)
        }

        if (!!searchString && !['', formVals.nameOfNominee].includes(searchString))
            fetcher()

    }, [searchString])


    useEffect(() => {
        if (!formVals)
            return
        var e
        if (!disabled)
            e = newProcessValidator(formVals)
        else
            e = existingProcessValidator(formVals)
        setErrors(e)
    }, [formVals])

    useEffect(() => {
        if (update == 'init')
            return

        if (update.isSearchDropdown) {
            var vals = { ...formVals }
            vals.nameOfNominee = update.value.label
            vals.emailOfNominee = update.value.email
            vals.nomineeManager = update.value.employeeManager
            vals.nomineeManagersEmail = update.value.employeeManagerEmail
            vals.employmentNumber = update.value.employeeNumber
            setFormVals(vals)
        }
        else
            updateFormVals(update, formVals, setFormVals)

    }, [update])
    return (
        <>
            <Grid title={"Ansvarig chef"} disabled={disabled}>
                <InputText
                    id={'managerName'}
                    label={"Ansvarig chef"}
                    text={formVals.managerName ?? ''}
                />
                <InputText
                    id={'createdDate'}
                    label={"Datum"}
                    text={formatDate(formVals.createdAt) ?? ''}
                />
            </Grid>
            <Grid title={"Mottagare"} disabled={disabled}>
                <InputText
                    id={'union'}
                    label={"Facklig organisation"}
                    text={formVals.union}
                />
            </Grid>
            <Grid title={"Ansökan avser"} disabled={disabled} errorMsgState={errors.nomineeAndPosition}>
                {disabled ?
                    <InputText
                        label={"Namn"}
                        text={formVals.nameOfNominee}
                    />
                    :
                    <DropdownSearch
                        label={"Namn på den anställda"}
                        searchString={searchString}
                        setSearchString={(e) => { setSearchString(e) }}
                        options={options}
                        placeholder={"Välj anställd"}
                        onSelect={(e) => {
                            e ? setUpdate({ value: e, isSearchDropdown: true })
                                : setUpdate({ id: "emailOfNominee", value: null })

                        }}
                    />
                }
                <InputText
                    id={'employmentNumber'}
                    label={"Anställningsnr"}
                    text={formVals.employmentNumber}
                    onChange={(e) => { setUpdate({ id: "employmentNumber", value: e }) }}
                />
                <InputText
                    id={'position'}
                    label={"Befattning"}
                    text={formVals.position}
                    onChange={(e) => { setUpdate({ id: "position", value: e }) }}
                    disabled={disabled}
                />
            </Grid>

            <GridSingles title={"Antal timmar övertidsuttag"}>
                <InformationButton
                    buttonText={"Läs mer"}
                    informationText={"När särskilda skäl föreligger får allmän övertid uttas med högst 200 timmar per kalenderår."}
                />

            </GridSingles>
            <GridQuadruple
                errorMsgState={errors.hoursOvertimeWithdrawal}
                disabled={disabled}
            >
                <InputText
                    id={'hoursOvertimeWithdrawals1'}
                    label={"Antal timmar"}
                    text={formVals.hoursOvertimeWithdrawals1}
                    onChange={(e) => { setUpdate({ id: "hoursOvertimeWithdrawals1", value: e }) }}
                    disabled={disabled}
                />
                <InputDate
                    id={'hoursOvertimeWithdrawalsFrom1'}
                    label={"Fr.o.m"}
                    text={formatDate(formVals.hoursOvertimeWithdrawalsFrom1)}
                    onChange={(e) => { setUpdate({ id: "hoursOvertimeWithdrawalsFrom1", value: e }) }}
                    disabled={disabled}
                />
                <InputDate
                    id={'hoursOvertimeWithdrawalsTo1'}
                    label={"T.o.m"}
                    text={formatDate(formVals.hoursOvertimeWithdrawalsTo1)}
                    onChange={(e) => { setUpdate({ id: "hoursOvertimeWithdrawalsTo1", value: e }) }}
                    disabled={disabled}
                />
                <InputText
                    id={'hoursOvertimeWithdrawalsReason1'}
                    label={"Orsak"}
                    text={formVals.hoursOvertimeWithdrawalsReason1}
                    onChange={(e) => { setUpdate({ id: "hoursOvertimeWithdrawalsReason1", value: e }) }}
                    disabled={disabled}
                />
                <InputText
                    id={'hoursOvertimeWithdrawals2'}
                    text={formVals.hoursOvertimeWithdrawals2}
                    onChange={(e) => { setUpdate({ id: "hoursOvertimeWithdrawals2", value: e }) }}
                    disabled={disabled}
                />
                <InputDate
                    id={'hoursOvertimeWithdrawalsFrom2'}
                    text={formatDate(formVals.hoursOvertimeWithdrawalsFrom2)}
                    onChange={(e) => { setUpdate({ id: "hoursOvertimeWithdrawalsFrom2", value: e }) }}
                    disabled={disabled}
                />
                <InputDate
                    id={'hoursOvertimeWithdrawalsTo2'}
                    text={formatDate(formVals.hoursOvertimeWithdrawalsTo2)}
                    onChange={(e) => { setUpdate({ id: "hoursOvertimeWithdrawalsTo2", value: e }) }}
                    disabled={disabled}
                />
                <InputText
                    id={'hoursOvertimeWithdrawalsReason2'}
                    text={formVals.hoursOvertimeWithdrawalsReason2}
                    onChange={(e) => { setUpdate({ id: "hoursOvertimeWithdrawalsReason2", value: e }) }}
                    disabled={disabled}
                />
                <InputText
                    id={'hoursOvertimeWithdrawals3'}
                    text={formVals.hoursOvertimeWithdrawals3}
                    onChange={(e) => { setUpdate({ id: "hoursOvertimeWithdrawals3", value: e }) }}
                    disabled={disabled}
                />
                <InputDate
                    id={'hoursOvertimeWithdrawalsFrom3'}
                    text={formatDate(formVals.hoursOvertimeWithdrawalsFrom3)}
                    onChange={(e) => { setUpdate({ id: "hoursOvertimeWithdrawalsFrom3", value: e }) }}
                    disabled={disabled}
                />
                <InputDate
                    id={'hoursOvertimeWithdrawalsTo3'}
                    text={formatDate(formVals.hoursOvertimeWithdrawalsTo3)}
                    onChange={(e) => { setUpdate({ id: "hoursOvertimeWithdrawalsTo3", value: e }) }}
                    disabled={disabled}
                />
                <InputText
                    id={'hoursOvertimeWithdrawalsReason3'}
                    text={formVals.hoursOvertimeWithdrawalsReason3}
                    onChange={(e) => { setUpdate({ id: "hoursOvertimeWithdrawalsReason3", value: e }) }}
                    disabled={disabled}
                />
                <InputText
                    id={'hoursOvertimeWithdrawals4'}
                    text={formVals.hoursOvertimeWithdrawals4}
                    onChange={(e) => { setUpdate({ id: "hoursOvertimeWithdrawals4", value: e }) }}
                    disabled={disabled}
                />
                <InputDate
                    id={'hoursOvertimeWithdrawalsFrom4'}
                    text={formatDate(formVals.hoursOvertimeWithdrawalsFrom4)}
                    onChange={(e) => { setUpdate({ id: "hoursOvertimeWithdrawalsFrom4", value: e }) }}
                    disabled={disabled}
                />
                <InputDate
                    id={'hoursOvertimeWithdrawalsTo4'}
                    text={formatDate(formVals.hoursOvertimeWithdrawalsTo4)}
                    onChange={(e) => { setUpdate({ id: "hoursOvertimeWithdrawalsTo4", value: e }) }}
                    disabled={disabled}
                />
                <InputText
                    id={'hoursOvertimeWithdrawalsReason4'}
                    text={formVals.hoursOvertimeWithdrawalsReason4}
                    onChange={(e) => { setUpdate({ id: "hoursOvertimeWithdrawalsReason4", value: e }) }}
                    disabled={disabled}
                />
            </GridQuadruple>
            <GridSingles title={"Antal timmar extra övertid"}>
                <InformationButton
                    buttonText={"Läs mer"}
                    informationText={"Utöver vad ovan sagts kan, när synnerliga skäl föreligger, överenskommelse träffas mellan arbetsgivaren och tjänstemannaklubben om uttag av extra övertid om 100 timmar per kalender år."}
                />
            </GridSingles>
            <GridQuadruple
                errorMsgState={errors.hoursExtraOvertime}
                disabled={disabled}
            >
                <InputText
                    id={'hoursExtraOvertime1'}
                    label={"Antal timmar"}
                    text={formVals.hoursExtraOvertime1}
                    onChange={(e) => { setUpdate({ id: "hoursExtraOvertime1", value: e }) }}
                    disabled={disabled}
                />
                <InputDate
                    id={'hoursExtraOvertimeFrom1'}
                    label={"Fr.o.m"}
                    text={formatDate(formVals.hoursExtraOvertimeFrom1)}
                    onChange={(e) => { setUpdate({ id: "hoursExtraOvertimeFrom1", value: e }) }}
                    disabled={disabled}
                />
                <InputDate
                    id={'hoursExtraOvertimeTo1'}
                    label={"T.o.m"}
                    text={formatDate(formVals.hoursExtraOvertimeTo1)}
                    onChange={(e) => { setUpdate({ id: "hoursExtraOvertimeTo1", value: e }) }}
                    disabled={disabled}
                />
                <InputText
                    id={'hoursExtraOvertimeReason1'}
                    label={"Orsak"}
                    text={formVals.hoursExtraOvertimeReason1}
                    onChange={(e) => { setUpdate({ id: "hoursExtraOvertimeReason1", value: e }) }}
                    disabled={disabled}
                />
                <InputText
                    id={'hoursExtraOvertime2'}
                    text={formVals.hoursExtraOvertime2}
                    onChange={(e) => { setUpdate({ id: "hoursExtraOvertime2", value: e }) }}
                    disabled={disabled}
                />
                <InputDate
                    id={'hoursExtraOvertimeFrom2'}
                    text={formatDate(formVals.hoursExtraOvertimeFrom2)}
                    onChange={(e) => { setUpdate({ id: "hoursExtraOvertimeFrom2", value: e }) }}
                    disabled={disabled}
                />
                <InputDate
                    id={'hoursExtraOvertimeTo2'}
                    text={formatDate(formVals.hoursExtraOvertimeTo2)}
                    onChange={(e) => { setUpdate({ id: "hoursExtraOvertimeTo2", value: e }) }}
                    disabled={disabled}
                />
                <InputText
                    id={'hoursExtraOvertimeReason2'}
                    text={formVals.hoursExtraOvertimeReason2}
                    onChange={(e) => { setUpdate({ id: "hoursExtraOvertimeReason2", value: e }) }}
                    disabled={disabled}
                />
                <InputText
                    id={'hoursExtraOvertime3'}
                    text={formVals.hoursExtraOvertime3}
                    onChange={(e) => { setUpdate({ id: "hoursExtraOvertime3", value: e }) }}
                    disabled={disabled}
                />
                <InputDate
                    id={'hoursExtraOvertimeFrom3'}
                    text={formatDate(formVals.hoursExtraOvertimeFrom3)}
                    onChange={(e) => { setUpdate({ id: "hoursExtraOvertimeFrom3", value: e }) }}
                    disabled={disabled}
                />
                <InputDate
                    id={'hoursExtraOvertimeTo3'}
                    text={formatDate(formVals.hoursExtraOvertimeTo3)}
                    onChange={(e) => { setUpdate({ id: "hoursExtraOvertimeTo3", value: e }) }}
                    disabled={disabled}
                />
                <InputText
                    id={'hoursExtraOvertimeReason3'}
                    text={formVals.hoursExtraOvertimeReason3}
                    onChange={(e) => { setUpdate({ id: "hoursExtraOvertimeReason3", value: e }) }}
                    disabled={disabled}
                />
                <InputText
                    id={'hoursExtraOvertime4'}
                    text={formVals.hoursExtraOvertime4}
                    onChange={(e) => { setUpdate({ id: "hoursExtraOvertime4", value: e }) }}
                    disabled={disabled}
                />
                <InputDate
                    id={'hoursExtraOvertimeFrom4'}
                    text={formatDate(formVals.hoursExtraOvertimeFrom4)}
                    onChange={(e) => { setUpdate({ id: "hoursExtraOvertimeFrom4", value: e }) }}
                    disabled={disabled}
                />
                <InputDate
                    id={'hoursExtraOvertimeTo4'}
                    text={formatDate(formVals.hoursExtraOvertimeTo4)}
                    onChange={(e) => { setUpdate({ id: "hoursExtraOvertimeTo4", value: e }) }}
                    disabled={disabled}
                />
                <InputText
                    id={'hoursExtraOvertimeReason4'}
                    text={formVals.hoursExtraOvertimeReason4}
                    onChange={(e) => { setUpdate({ id: "hoursExtraOvertimeReason4", value: e }) }}
                    disabled={disabled}
                />
            </GridQuadruple>
            <GridSingles
                title={"Övrigt"}
                disabled={disabled}
            >
                <InputTextArea
                    id={'other'}
                    title={""}
                    onChange={(e) => { setUpdate({ id: "other", value: e }) }}
                    text={formVals.other}
                />
            </GridSingles>

        </>
    )
}

export default OvertimeForm