import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import LeaveExisting from '../../organisms/LeaveExisting/LeaveExisting'
import LeaveNew from '../../organisms/LeaveNew/LeaveNew'

const LeaveProcess = ({ setTitle }) => {
    const { id, draft } = useParams()

    useEffect(() => {
        setTitle('Ledighetsansökan')
    }, [])

    return (
        id ?
            <LeaveExisting id={id} isDraft={draft} />
            :
            <LeaveNew />
    )
}

export default LeaveProcess