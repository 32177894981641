import React from "react"
import SupportEmail from "./SupportEmail"
import { useState } from "react";
import "./SupportEmailModal.css"

const SupportEmailModal = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    return (
        <div className={`ModalContainer ${isModalOpen ? 'open' : ''}`}>
            <div className="tab" onClick={toggleModal}>
                Rapportera fel
            </div>
            <div className="ModalContent">
                <button className="close-button" onClick={() => { setIsModalOpen(false) }}>
                    X
                </button>
                <SupportEmail />
            </div>
        </div>
    )
}

export default SupportEmailModal