import React from "react"
import './DecisionModal.css'
import BaseForm from "../../organisms/BaseForm/BaseForm"
import ButtonsContainer from "../ButtonsContainer/ButtonsContainer"
import Button from "../../atoms/Button/Button"
import SecondaryButton from "../../atoms/Button/SecondaryButton"
import Dropdown from "../StyledSelect/Dropdown"
import CheckBox from "../../atoms/Check/CheckBox"
import InputTextArea from "../InputTextArea/InputTextArea"
import { updateFormVals } from "../../../helpers/update"
import { useEffect, useState } from "react"
import GridSingles from "../../atoms/Grid/GridSingles"

const DecisionModal = ({ isOpen, setIsOpen, onSubmit, formVals, setFormVals, options, decision }) => {
  const [errors, setErrors] = useState([])
  useEffect(() => {
    const e = {}
    if (!formVals?.comment || (!formVals?.target && decision === 'needmoreinfo')) {
      e.completion = 'Fyll i all information'
    }
    if (Object.keys(e).length !== 0) e.isValid = false
    else e.isValid = true
    setErrors(e)
  }, [formVals])

  return (
    <div className={`decision_modal ${isOpen && 'open'}`}>
      {isOpen &&
        <BaseForm>
          <GridSingles
            title={decision === 'needmoreinfo' ? 'Begär komplettering' : "Neka"}
            errorMsgState={errors?.completion}
          >
            {decision === 'needmoreinfo' &&
              <Dropdown
                label={"Vem ska komplettera"}
                options={options}
                id='target'
                selectedValue={formVals?.process ? "process" : formVals?.target}
                onSelect={(e) => {
                  setFormVals({ ...formVals, target: e })
                }}
              />
            }

            <InputTextArea
              title={"Kommentar"}
              id={`${decision}Comment`}
              onChange={(e) => {
                updateFormVals({ id: 'comment', value: e }, formVals, setFormVals)
              }}
            />

            {decision === 'needmoreinfo' &&
              <CheckBox
                label={"Tillbaka till mig efter komplettering"}
                value={"backToLatest"}
                onChange={(e) => {
                  setFormVals({ ...formVals, backToLatest: e.target?.checked })
                }}
              />
            }
            <ButtonsContainer>
              <SecondaryButton
                text={"Avbryt"}
                onClick={() => {
                  setFormVals({ ...formVals, comment: null })
                  setIsOpen(false)
                }}
              />

              <Button
                text={"Skicka"}
                onClick={onSubmit}
                disabled={!errors?.isValid}
              />
            </ButtonsContainer>
          </GridSingles>
        </BaseForm>
      }
    </div>
  )
}
export default DecisionModal