import React, { Children, cloneElement } from 'react'
import Grid from './Grid'

const GridQuadruple = ({ title = undefined, errorMsgState = undefined, children, disabled = false }) => {


    return (
        <Grid
            title={title}
            gridTemplateColumnsCss={"25% 25% 25% 25% "}
            disabled={disabled}
            errorMsgState={errorMsgState}
        >
            {children}
        </Grid>
    )
}

export default GridQuadruple