import React from 'react'
import Header from '../../molecules/Header/Header'
import SupportEmailModal from '../../organisms/SupportEmail/SupportEmailModal'

const ProcessPage = ({ linkPrefix, titleNewProcess, contentComponent }) => {
  return (
    <>
      <Header linkPrefix={linkPrefix} titleNewProcess={titleNewProcess} />
      <SupportEmailModal />
      {contentComponent}
    </>
  )
}

export default ProcessPage
