import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import RecruitExisting from '../../organisms/RecruitExisting/RecruitExisting'
import RecruitNew from '../../organisms/RecruitNew/RecruitNew'

const RecruitProcess = ({ setTitle }) => {
    const { id, draft } = useParams()

    useEffect(() => {
        setTitle('Finders Fee – Rekryteringsbonus')
    }, [])

    return (
        id ?
            <RecruitExisting id={id} isDraft={draft} />
            :
            <RecruitNew />
    )
}

export default RecruitProcess