import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
// import { PublicClientApplication } from '@azure/msal-browser'  Put back when using MSAL again
// import { MsalProvider } from '@azure/msal-react'  Put back when using MSAL again
import { SimpleAuthProvider } from './helpers/SimpleAuthProvider'
import App from './App'
// import { msalConfig } from './authConfig'  Put back when using MSAL again
import './styles/global.css'

// const msalInstance = new PublicClientApplication(msalConfig)  Put back when using MSAL again

ReactDOM.render(
  <React.StrictMode>
    <SimpleAuthProvider>
      {/* <MsalProvider instance={msalInstance}>  Put back when using MSAL again */}
      <BrowserRouter>
        <App />
      </BrowserRouter>
      {/* </MsalProvider>   Put back when using MSAL again*/}
    </SimpleAuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
