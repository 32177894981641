const Colors =
{
    RED: '#EA0000',
    MAGENTA: '#D80084',
    BLUE: '#2400B0',
    CYAN: '#00E7EF',
    EMERALD: '#008224',
    LIME: '#61D600',
    YELLOW: '#FFE700',
    ORANGE: '#FF8000',
    BLACK: '#000000',
    GRAY_1: '#3C3C3C',
    GRAY_2: '#6D6E70',
    GRAY_3: '#DCDCDC',
    GRAY_4: '#EFEFEF',
    WHITE: '#FFFFFF',
}
export default Colors; 