import React from 'react'
import './Input.css'
import validateStyles from '../../../styles/Common/validateStyles.module.css'

const InputText = ({ id, label, text, onChange = undefined, onBlur = undefined, validationMsg = undefined, disabled = true, required }) => {
  var cssClass = 'inputText'
  if (disabled)
    cssClass += " " + 'disableInputText'
  if (validationMsg)
    cssClass += " " + validateStyles.notValid

  return (
    <div>
      <label className='inputTextLabel' aria-required={required} htmlFor={id}>{label}</label>
      <input
        id={id}
        className={cssClass}
        onChange={(e) => {
          onChange(e.target.value)
        }}
        onBlur={onBlur}
        type={"text"}
        disabled={disabled}
        value={text}
      />
      {validationMsg && (
        <p className={validateStyles.errorParagraph}>{validationMsg}</p>
      )}
    </div>
  )
}

export default InputText