import ProcessList from './ProcessList'
import './MultiProcessList.css'

const ProcessListDecision = ({ processType }) => {
  return (
    <div className='multi_processList'>
      <h1 className='processList_row--header'>VÄNTAR PÅ DIG</h1>
      <ProcessList endpoint={'Processes/WaitingUsersDecision/'} processType={processType} process={"ongoingActivities"} />
      <h1 className='processList_row--header'>KOMMANDE</h1>
      <ProcessList endpoint={'Processes/WillNeedUsersDecision/'} processType={processType} process={"ongoingActivities"} />
      <h1 className='processList_row--header'>PROCESSER MED STATUS KOMPLETTERING</h1>
      <ProcessList endpoint={'Processes/DoneUsersDecision/'} processType={processType} process={"ongoingActivities"} />
    </div>
  )
}

export default ProcessListDecision
