import { Link, useNavigate } from 'react-router-dom'
import './HomeMenu.css'
import homeImg from '../../../assets/home.webp'
import { useEffect, useState, useContext } from 'react'
import { requestMaker } from '../../../helpers/requestMaker'
import { SimpleAuthContext } from '../../../helpers/SimpleAuthProvider'

const HomeMenu = () => {
  var { user } = useContext(SimpleAuthContext)
  const navigate = useNavigate()
  const [dataFromFetch, setDataFromFetch] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      const data = await requestMaker(
        user,
        'GET',
        'User/GetUserPermission',
        localStorage.getItem('token')
      )
      setDataFromFetch(data)
    }
    fetchData()
  }, [])
  return (
    <>
      <div className='processesList'>
        <ul>
          <img
            alt="Home button"
            className='homeButton'
            src={homeImg}
            onClick={() => {
              navigate('/')
            }}
          />
          <li>
            <Link to={'/allOngoing'}>MINA PÅGÅENDE ANSÖKNINGAR</Link>
          </li>
          <li>
            <Link to={'/drafts'}>MINA UTKAST</Link>
          </li>
          <li>
            <Link to={'/allCompleted'}>MINA AVSLUTADE ANSÖKNINGAR</Link>
          </li>
          {dataFromFetch.isDecisionMaker && (
            <>
              <li>
                <Link to={'/allOngoingActivities'}>PÅGÅENDE AKTIVITETER</Link>
              </li>
              <li>
                <Link to={'/allCompletedActivities'}>AVSLUTADE AKTIVITETER</Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </>
  )
}

export default HomeMenu
