import React, { useContext, useEffect, useState } from "react";
import formatDate from "../../../helpers/formatters";
import Grid from "../../atoms/Grid/Grid";
import GridSingles from "../../atoms/Grid/GridSingles";
import InputTextArea from "../../molecules/InputTextArea/InputTextArea";
import InputText from "../../molecules/Input/InputText";
import DropdownSearch from "../../molecules/StyledSelect/DropdownSearch";
import { requestMaker } from "../../../helpers/requestMaker";
import { SimpleAuthContext } from "../../../helpers/SimpleAuthProvider";
import { handleFormUpdate, updateFormVals } from "../../../helpers/update";
import { scrollToPos } from "../../../helpers/scrollToElement";
import DecisionMaker from '../../../assets/DecisionMaker'
import {
  stepIndicatorsProps,
  isCompleted,
  isFurtherInfoStep,
  isStep,
} from '../../../helpers/formFunctions'

const BonusForm = ({
  formVals,
  setFormVals,
  errors,
  setErrors,
  decisionStep,
  disabled = false,
}) => {
  const [searchString, setSearchString] = useState(
    formVals.nameOfNominee ?? ""
  );
  const [update, setUpdate] = useState("init");
  const [options, setOptions] = useState([]);
  var { user } = useContext(SimpleAuthContext);

  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetcher() {
      let data = await requestMaker(
        user,
        "POST",
        "employee/searchemployees",
        token,
        searchString
      );
      setOptions(data);
    }

    if (!!searchString && !["", formVals.nameOfNominee].includes(searchString))
      fetcher();
  }, [searchString]);

  useEffect(() => {
    
    
    if (!formVals) return;
    const e = {};
    if (!formVals?.nameOfNominee) {
      e.nominee = "Välj medarbetare";
    }
    if (!formVals?.emailOfNominee) {
      e.nominee = "Välj medarbetare";
    }
    if (Object.keys(e).length !== 0) {
      const firstErrorDiv = document.getElementById(Object.keys(e)[0]);
      firstErrorDiv && scrollToPos(firstErrorDiv);
    } else e.isValid = true;
    setErrors(e);

  }, [formVals]);

  useEffect(() => {
    if (update == "init") return;

    if (update.isSearchDropdown) {
      var vals = { ...formVals };
      vals.nameOfNominee = update.value.label;
      vals.emailOfNominee = update.value.email;
      vals.nomineeManager = update.value.employeeManager;
      vals.nomineeManagersEmail = update.value.employeeManagerEmail;
      vals.employmentNumber = update.value.employeeNumber;
      setFormVals(vals);
      

    } else updateFormVals(update, formVals, setFormVals);
  }, [update]);
  // Assuming userName and formVals are defined and contain appropriate values
var userName = user.firstName + ' ' + user.lastName;
const isManager = formVals.managerName == userName;
  return (
    <>
      <Grid title={"Ansvarig chef"} disabled={disabled}>
        <InputText
          id={"managerName"}
          label={"Ansvarig chef"}
          text={formVals.managerName ?? ""}
        />
        <InputText
          id={"createdAt"}
          label={"Datum"}
          text={formatDate(formVals.createdAt) ?? ""}
        />
      </Grid>

      <Grid
        title={"Anställningsupgifter"}
        disabled={disabled}
        errorMsgState={errors.nominee}
      >
        <InputText label={"Medarbetarensnamn"} text={formVals.nameOfNominee} />
        <InputText
          id={"employmentNumber"}
          label={"Anställningsnr"}
          text={formVals.employmentNumber}
          onChange={(e) => {
            setUpdate({ id: "employmentNumber", value: e });
          }}
        />
      </Grid>
      <Grid
        title={"Uppgifter om utbetalning"} // disabled={disabled}
      >

        <InputText
          id={"amount"}
          label={"Belopp"}
          text={formVals.amount}
          onChange={(e) => {
            var v;
            if (e) v = isNaN(e) ? e : parseInt(e);
            setUpdate({ id: "amount", value: v });
          }}
          disabled={!decisionStep}
        />


        <InputText
          id={"payDate"}
          label={"Bonus utbetalas månad"}
          text={formatDate(formVals.payDate)}
          onChange={(e) => {
            setUpdate({ id: "payDate", value: e });
          }}
          disabled={!decisionStep}
        />
      </Grid>
      <InputText
        id={"bonusFor"}
        label={"Bonus avser"}
        text={formVals.bonusFor}
        onChange={(e) => {
          setUpdate({ id: "bonusFor", value: e });
        }}
        disabled={disabled}
      />
      <GridSingles title={"Övriga uppgifter"} disabled={disabled}>
        <InputTextArea
          id={"other"}
          title={""}
          onChange={(e) => {
            setUpdate({ id: "other", value: e });
          }}
          text={formVals.other}
        />
      </GridSingles>
    </>
  );
};

export default BonusForm;
