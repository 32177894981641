import React from 'react'
import Select from 'react-select'
import './Select.css'

const Dropdown = ({ label, id, options, onSelect, disabled, selectedValue }) => {
	const ops = options.map((x) => {
		if (typeof x === 'object' && x !== null) return x;
		else return { value: x, label: x };
	});

	const selectedOption = ops.find((option) => option.value === selectedValue);

	return (
		<div>
			<div>{label}
				<Select
					placeholder="Välj..."
					options={ops}
					isClearable={true}
					isDisabled={disabled}
					value={selectedOption}
					onChange={(opt) => {
						onSelect(opt.value);
					}}
					id={id}
					className="inputTextTemp"
				/>
			</div>
		</div>
	);
};

export default Dropdown;
