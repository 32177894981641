import styles from './Check.css'

const Check = ({ inputProps, labelText,kursivBeskrivning }) => {
  return (
    <div className='checkBoxChildContainer'>
      <input
        className='checkBoxInput'
        id={inputProps.id}
        name={inputProps.name}
        type={inputProps.type}
        disabled={inputProps.disabled}
        defaultChecked={inputProps.defaultChecked}
        value={inputProps.value}
        onClick={
          inputProps.type === 'radio'
            ? inputProps.handleChange
            : (e) => {
              inputProps.handleChange(e)
              inputProps.onChangeFunc?.()
            }
        }
        checked={inputProps.checked}
        readOnly
      />
      <label className='checkBoxLabel' htmlFor={inputProps.id}>
        {labelText}
      </label>
      {kursivBeskrivning != null ? <i>&nbsp;{' ' + kursivBeskrivning}</i> : null}
    </div>
  )
}
export default Check
