import './LoadingSpinner.css'

const LoadingSpinner = () => {
  return (
    <div className='spinnerContainer'>
      <div className='loadingSpinner'></div>
    </div>
  )
}
export default LoadingSpinner
