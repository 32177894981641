import { isFurtherInfoStep, optionsArrayForDropDown } from "../../../helpers/formFunctions"
import ButtonsContainer from "../../molecules/ButtonsContainer/ButtonsContainer"
import Button from "../../atoms/Button/Button"
import SecondaryButton from "../../atoms/Button/SecondaryButton"
import TertiaryButton from "../../atoms/Button/TertiaryButton"
import DecisionModal from "../../molecules/DecisionModal/DecisionModal"
import { deleteDraft, submitFinishProcess, submitNewProcess, submitUpdateProcess } from "../../../helpers/processApi"
import { useState } from "react"

const FormButtons = ({ dataFetch, user, formVals, setFormVals, setModalIsOpen, setModalMessage, setIsLoading, errors, id }) => {
  var processType = dataFetch.processType
  const [decisionModalIsOpen, setDecisionModalIsOpen] = useState(false)
  const [decision, setDecision] = useState('')
  return (
    <>
      {dataFetch && formVals && (

        //Utkast
        dataFetch?.isDraft ? (
          <ButtonsContainer>
            <Button
              onClick={(e) => {
                deleteDraft(
                  formVals,
                  processType,
                  dataFetch.id,
                  user,
                  setIsLoading,
                  setModalIsOpen,
                  setModalMessage,
                )
              }}
              text='Radera utkast'
            />
            <Button
              onClick={(e) => {
                submitNewProcess(
                  formVals,
                  processType,
                  dataFetch.id,
                  user,
                  setIsLoading,
                  setModalIsOpen,
                  setModalMessage,
                )
              }}
              text='Skicka för godkännande'
              disabled={!errors.isValid}
            />
          </ButtonsContainer>
        ) :

          //Komplettering
          isFurtherInfoStep(dataFetch) ? (
            <ButtonsContainer>
              <Button
                onClick={(e) =>
                  submitFinishProcess(
                    formVals,
                    id,
                    user,
                    setIsLoading,
                    setModalIsOpen,
                    setModalMessage,
                  )
                }
                text='Skicka komplettering'
                disabled={!errors.isValid}
              />
            </ButtonsContainer>
          ) :

            //DEFAULT
            <ButtonsContainer>
              <SecondaryButton
                disabled={!errors.isValid}
                onClick={() => {
                  setDecision('decline')
                  setDecisionModalIsOpen(true)
                }}
                text='Neka'
              />
              <TertiaryButton
                disabled={!errors.isValid}
                onClick={() => {
                  setDecision('needmoreinfo')
                  setDecisionModalIsOpen(true)
                }}
                text='Begär komplettering'
              />
              <Button
                disabled={!errors.isValid}
                onClick={(e) =>
                  submitUpdateProcess(
                    formVals,
                    'approve',
                    id,
                    user,
                    setIsLoading,
                    setModalIsOpen,
                    setModalMessage,
                  )
                }
                text='Godkänn'
              />
              {decisionModalIsOpen && (
                <DecisionModal
                  isOpen={decisionModalIsOpen}
                  setIsOpen={setDecisionModalIsOpen}
                  onSubmit={(e) =>
                    submitUpdateProcess(
                      formVals,
                      decision,
                      id,
                      user,
                      setIsLoading,
                      setModalIsOpen,
                      setModalMessage,
                    )
                  }
                  formVals={formVals}
                  setFormVals={setFormVals}
                  options={optionsArrayForDropDown(dataFetch)}
                  decision={decision}
                />
              )}
            </ButtonsContainer>
      )
      }
    </>
  )
}
export default FormButtons