import React, { useContext, useEffect, useState } from 'react'
import CheckBox from '../../atoms/Check/CheckBox'
import CommentText from '../../molecules/CommentText/CommentText'
import formatDate from '../../../helpers/formatters'
import Grid from '../../atoms/Grid/Grid'
import GridSingles from '../../atoms/Grid/GridSingles'
import InputTextArea from '../../molecules/InputTextArea/InputTextArea'
import InputText from '../../molecules/Input/InputText'
import DropdownSearch from '../../molecules/StyledSelect/DropdownSearch'
import { requestMaker } from '../../../helpers/requestMaker'
import { SimpleAuthContext } from '../../../helpers/SimpleAuthProvider'
import { scrollToPos } from '../../../helpers/scrollToElement'
import { updateFormVals } from '../../../helpers/update'
import { containsOldCategories } from '../../../helpers/formFunctions'

const VipForm = ({ formVals, setFormVals, errors, setErrors, disabled = false }) => {
  const [searchString, setSearchString] = useState(formVals.nameOfNominee ?? '')
  const [update, setUpdate] = useState('init')
  const [options, setOptions] = useState([])
  var { user } = useContext(SimpleAuthContext);

  useEffect(() => {
    const token = localStorage.getItem('token')
    async function fetcher() {
      let data = await requestMaker(
        user,
        'POST',
        "employee/searchemployees",
        token,
        searchString
      )
      setOptions(data)
    }

    if (!!searchString && !['', formVals.nameOfNominee].includes(searchString))
      fetcher()

  }, [searchString])

  useEffect(() => {
    if (!formVals)
      return
    const e = {}
    if (!formVals?.emailOfNominee || !formVals?.employmentNumber) {
      e.nominee = 'En kollega måste nomineras'
    }
    if (formVals.nominationCategories && !Object.values(formVals.nominationCategories).includes(true)) {
      e.checkBoxes = 'Minst en värdering måste finnas'
    }
    if (!formVals?.motivationOfNomination) {
      e.motivationOfNomination = 'Motivering till nomineringen är obligatoriskt'
    }

    if (Object.keys(e).length !== 0) {
      const firstErrorDiv = document.getElementById(Object.keys(e)[0])
      firstErrorDiv && scrollToPos(firstErrorDiv)
    }
    else
      e.isValid = true
    setErrors(e)
  }, [formVals])

  useEffect(() => {
    if (update == 'init') {
      if (!formVals.nominationCategories)
        setUpdate({ id: "nominationCategories", value: { "Visa ansvar och engagemang": false, "Framhäva samarbete och synergi": false, "Fokusera på vinst och tillväxt": false } })
      return
    }

    if (update.id === 'nominationCategories') {
      const updatedNominationCategories = { ...formVals.nominationCategories, ...update.value }
      const updatedFormVals = { ...formVals, nominationCategories: updatedNominationCategories }
      setFormVals(updatedFormVals)
      return
    }

    if (update.isSearchDropdown) {
      var vals = { ...formVals }
      vals.nameOfNominee = update.value.label
      vals.emailOfNominee = update.value.email
      vals.nomineeManager = update.value.employeeManager
      vals.nomineeManagersEmail = update.value.employeeManagerEmail
      vals.employmentNumber = update.value.employeeNumber
      setFormVals(vals)
    }
    else
      updateFormVals(update, formVals, setFormVals)
  }, [update])
  return (
    <>
      <Grid title={"Nominering"} errorMsgState={errors.nominee} disabled={disabled}>
        <InputText
          id={'id'}
          label={"Datum"}
          text={formatDate(formVals.createdAt) ?? ''}
        />
        <InputText
          id={'nominatedBy'}
          label={"Nominerad av"}
          text={formVals.initiatorEmail}
        />
        {disabled ?
          <InputText
            label={"Namn på den nominerade"}
            text={formVals.nameOfNominee}
          />
          :
          <DropdownSearch
            label={"Namn på den nominerade"}
            searchString={searchString}
            setSearchString={(e) => { setSearchString(e) }}
            options={options}
            placeholder={"Välj nominerades namn"}
            onSelect={(e) => {
              e ? setUpdate({ value: e, isSearchDropdown: true })
                : setUpdate({ id: "emailOfNominee", value: null })
            }}
          />
        }
        <InputText
          id={'employmentNumber'}
          label={"Nominerades anställningsnr"}
          text={formVals.employmentNumber ?? ''}
        />
        <InputText
          id={'nomineeManager'}
          label={"Nominerades chef"}
          text={formVals.nomineeManager ?? ''}
        />
        <InputText
          id={'nomineeManagersEmail'}
          label={"Nominerades chefs email"}
          text={formVals.nomineeManagersEmail ?? ''}
        />
      </Grid>
      {containsOldCategories(formVals)
        ?
        <Grid
          title={"Fujitsu värderingar"}
          errorMsgState={errors.checkBoxes}
          disabled={disabled}
        >
          <CheckBox
            label={"Leverera oklanderlig service"}
            value={"goodService"}
            isChecked={formVals.nominationCategories["Leverera oklanderlig service"] === true}
            onChange={(e) => {
              setUpdate({ id: "nominationCategories", value: { "Leverera oklanderlig service": e.target.checked } });
            }}
          />
          <CheckBox
            label={"Sätta kunden först"}
            value={"customerFocus"}
            isChecked={formVals.nominationCategories["Sätta kunden först"] === true}
            onChange={(e) => {
              setUpdate({ id: "nominationCategories", value: { "Sätta kunden först": e.target.checked } });
            }}
          />
          <CheckBox
            label={"Fokusera på tillväxt och vinst"}
            value={"growthAndResult"}
            isChecked={formVals.nominationCategories["Fokusera på tillväxt och vinst"] === true}
            onChange={(e) => {
              setUpdate({ id: "nominationCategories", value: { "Fokusera på tillväxt och vinst": e.target.checked } });
            }}
          />
          <CheckBox
            label={"Skapa sin egen framgång"}
            value={"createYourSuccess"}
            isChecked={formVals.nominationCategories && formVals.nominationCategories["Skapa sin egen framgång"] === true}
            onChange={(e) => {
              setUpdate({ id: "nominationCategories", value: { "Skapa sin egen framgång": e.target.checked } });
            }}
          />

        </Grid>
        :
        <Grid
          title={"Fujitsu värderingar"}
          errorMsgState={errors.checkBoxes}
          disabled={disabled}
          gridTemplateColumnsCss={'100%'}
        >
          <CheckBox
            label={"Business Mindset"}
            kursivBeskrivning={'- Skapar långsiktigt förtroende och hållbar finansiell tillväxt.'}
            value={"businessMinded"}
            isChecked={formVals.nominationCategories && formVals.nominationCategories['Fokusera på vinst och tillväxt'] === true}
            onChange={(e) => {
              setUpdate({ id: "nominationCategories", value: { "Fokusera på vinst och tillväxt": e.target.checked } })
            }}
          />
          <CheckBox
            label={"Supporting Society"}
            kursivBeskrivning={'- Bidrar till vårt syfte att stötta samhället.'}
            value={"actResponsible"}
            isChecked={formVals.nominationCategories && formVals.nominationCategories["Visa ansvar och engagemang"] === true}
            onChange={(e) => {
              setUpdate({ id: "nominationCategories", value: { 'Visa ansvar och engagemang': e.target.checked } })
            }}
          />
          <CheckBox
            label={"Collaboration"}
            kursivBeskrivning={'- Perspektivbreddar genom att samarbeta tvärfunktionellt.'}
            value={"collaboration"}
            isChecked={formVals.nominationCategories && formVals.nominationCategories["Framhäva samarbete och synergi"] === true}
            onChange={(e) => {
              setUpdate({ id: "nominationCategories", value: { "Framhäva samarbete och synergi": e.target.checked } })
            }}
          />
        </Grid>
      }
      <GridSingles
        title={"Motivering till nomineringen"}
        errorMsgState={errors.motivationOfNomination}
        disabled={disabled}
      >
        <InputTextArea
          id={'motivationOfNomination'}
          title={"Motivering - Berätta hur individens handlingar reflekterats i Fujitsus värderingar"}
          kursivBeskrivning={"Ge gärna exempel på vilken effekt det har haft på ex, vinst, besparing och eller ökad effektivitet för Fujitsu / Kund."}
          onChange={(e) => {
            setUpdate({ id: "motivationOfNomination", value: e })
          }}
          text={formVals.motivationOfNomination}
          />
        <CommentText>
          I motiveringen till nomineringen ska den nominerades förmåga att leva
          upp till Fujitsu Swedens värderingar beaktas. Lyft därför i din
          motivering fram just de värderingar som du tycker är kännetecknande
          för din kandidat.
        </CommentText>
      </GridSingles>
      <Grid>
        <InputText
          id={"amount"}
          label={"Belopp"}
          text={formVals.amount ?? ''}
        />
      </Grid>
    </>
  )
}

export default VipForm