const DecisionMaker =
{
    INITIATED_BY: 0,
    MANAGER: 1,
    HR: 2,
    MD: 3,
    CAR_ADMIN: 4,
    FINANCE: 5,
    PAYROLL: 6,
    UNION: 7,
    NO_DECISION: 8
}
export default DecisionMaker; 