const isVisible = (ele) => {
  const { top, bottom } = ele?.getBoundingClientRect() ?? {}
  const vHeight = window.innerHeight || document.documentElement.clientHeight

  return (top > 0 || bottom > 0) && top < vHeight
}
const getScrollPos = (elemntToGetScrollPos) => {
  return (
    elemntToGetScrollPos?.getBoundingClientRect()?.top +
    window.pageYOffset -
    100
  )
}
export const scrollToPos = (element) => {
  const ShouldScroll = isVisible(element)
  if (!ShouldScroll) {
    const y = getScrollPos(element)
    window.scrollTo({ top: y, behavior: 'smooth' })
  }
}
